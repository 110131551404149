<template>
  <img :src="QRUrl" alt="" class="qr-img" />
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'QRCode',
  data() {
    return {
      QRUrl: ''
    }
  },
  props: {
    text: {
      type: String,
      default: 'text'
    }
  },
  watch: {
    text() {
      this.getUrl(this.text)
    }
  },
  methods: {
    getUrl() {
      QRCode.toDataURL(
        this.text,
        {
          errorCorrectionLevel: 'L',
          margin: 0
        },
        (err, url) => {
          if (err) {
            console.log('qr error', err)
            return
          }
          this.QRUrl = url
        }
      )
    }
  },
  mounted() {
    this.getUrl()
  }
}
</script>

<style scoped>
.qr-img {
  display: block;
  width: 100%;
}
</style>
