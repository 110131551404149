import { gsap } from '@/tools/gsap'

/**
 * 请求取消管理
 */
export class CancelHttp {
  constructor() {
    // 单例模式
    if (CancelHttp.instance) {
      return CancelHttp.instance
    }

    // 取消请求方法集合
    this.sources = {}
    // 用做id的计数器
    this.counter = 0
    // api所包含的取消请求key集合
    this.apis = {}

    // 保存单例
    CancelHttp.instance = this
  }

  /**
   * 增加需要管理的取消请求函数
   * @param {string} api - 请求api的名字
   * @param {object} source - 取消请求的CancelToken对象
   * @return {string} key - 取消请求的唯一标识key
   */
  add(api, source) {
    // 生成key
    const key = String(this.counter)
    this.counter += 1
    // 缓存取消方法
    this.sources[key] = source
    if (this.apis[api] === undefined) {
      this.apis[api] = []
    }
    this.apis[api].push(key)
    // 返回key
    return key
  }

  /**
   * 取消一个请求
   * @param {string} key - 取消请求的唯一标识key
   */
  fire(key) {
    if (this.sources[key] === undefined) {
      return
    }
    this.sources[key].cancel('Manually cancel the request')
    delete this.sources[key]
  }

  /**
   * 取消一个api下的所有请求
   * @param {string} api - 请求api的名字
   */
  fireApi(api) {
    if (this.apis[api] === undefined) {
      return
    }
    this.apis[api].forEach(key => {
      this.fire(key)
    })
    this.apis[api] = []
  }

  /**
   * 取消所有的请求
   */
  fireAll() {
    for (let key in this.sources) {
      this.sources[key].cancel('手动取消请求')
    }
    this.sources = {}
    this.apis = {}
  }

  /**
   * 移除取消请求对象
   * @param {string} key - 取消请求的唯一标识key
   */
  remove(key) {
    if (this.sources[key] === undefined) {
      return
    }
    delete this.sources[key]
  }
}

export function getImgs(doc) {
  return Array.from(doc.getElementsByTagName('img')).map(img => img.src)
}
export function getBgImgs(doc) {
  const srcChecker = /url\(\s*?['"]?\s*?(\S+?)\s*?["']?\s*?\)/i
  return Array.from(
    Array.from(doc.querySelectorAll('*')).reduce((collection, node) => {
      let prop = window
        .getComputedStyle(node, null)
        .getPropertyValue('background-image')
      // match `url(...)`
      let match = srcChecker.exec(prop)
      if (match) {
        collection.add(match[1])
      }
      return collection
    }, new Set())
  )
}
export function filterImageData(list) {
  // 去重
  const unique = Array.from(new Set(list))
  // 去除base64
  const noBase = unique.filter(item => {
    return item.search(/^data:(.+?);base64/) === -1
  })
  return noBase
}
export function imageReady(list) {
  if (list.length === 0) {
    return Promise.resolve()
  }
  const promisList = []
  list.forEach(item => {
    const imgEl = document.createElement('img')
    const promis = new Promise(resolve => {
      imgEl.addEventListener('load', () => {
        resolve()
      })
      imgEl.src = item
    })
    promisList.push(promis)
  })
  return promisList
}

export function resourceReady(doc) {
  const img = getImgs(doc)
  const bgImg = getBgImgs(doc)
  const imgList = filterImageData([...img, ...bgImg])
  const imgPromiseList = imageReady(imgList)
  const promiseList = [...imgPromiseList, document.fonts.ready]
  return Promise.all(promiseList)
}

export function animeTitleShow(para) {
  // 默认为bottom
  let textMove = {
    start: { y: 50 },
    end: { y: 0 }
  }
  if (para.move === 'left') {
    textMove = {
      start: { x: -50 },
      end: { x: 0 }
    }
  }
  const title = gsap.fromTo(
    para.titleEl,
    {
      transform: 'translateY(calc(100% - 20px))'
    },
    {
      y: 0,
      duration: 1
    }
  )
  let text
  if (para.textEl !== undefined) {
    text = gsap.fromTo(
      para.textEl,
      {
        opacity: 0,
        ...textMove.start
      },
      {
        opacity: 1,
        ...textMove.end,
        duration: 0.6,
        delay: 0.3
      }
    )
  }
  let option = {}
  if (para.option) {
    option = para.option
  }
  let scrollTriggerOption = {}
  if (para.scrollTrigger) {
    scrollTriggerOption = para.scrollTrigger
  }
  const tl = gsap.timeline({
    ...option,
    scrollTrigger: {
      ...scrollTriggerOption
      // markers: process.env.NODE_ENV === 'development'
    }
  })
  if (para.startAnime) {
    tl.add(para.startAnime)
  }
  tl.add(title)
  if (para.textEl !== undefined) {
    tl.add(text, '-=1')
  }
  return tl
}

export function getNightState() {
  // const nowHours = new Date().getHours()
  // return nowHours > 18 || nowHours < 8
  return true
}
