var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-button font-bold",class:[
    _vm.type,
    { disable: _vm.disable },
    { loading: _vm.loading },
    { hoverForever: _vm.hoverForever },
    { active: _vm.active },
    { 'no-hover-active': _vm.noHoverActive }
  ],style:({
    '--t': _vm.comicShadow + 'px',
    height: _vm.height + 'px',
    minWidth: _vm.minWidth,
    paddingLeft: _vm.paddingLR,
    paddingRight: _vm.paddingLR
  }),on:{"click":_vm.handle,"mouseenter":_vm.enter,"mousemove":_vm.move,"mouseleave":_vm.leave}},[_c('div',{staticClass:"c-button-bg"}),_c('div',{staticClass:"c-button-text"},[(_vm.loading)?_c('div',{staticClass:"c-button-loading"},[_c('i',{staticClass:"common-icon-loading roll"})]):[_vm._t("default")]],2),_c('div',{staticClass:"c-button-ripple"},_vm._l((_vm.ripple),function(item){return _c('div',{key:item,staticClass:"c-button-ripple-item",style:({ left: _vm.rippleX + 'px', top: _vm.rippleY + 'px' })})}),0),(_vm.active)?_c('div',{staticClass:"c-button-mask",on:{"mouseenter":_vm.activeHandle}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }