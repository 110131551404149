import action from '@/http/axios'
// import { CancelHttp } from '@/tools/utils'

// const cancelHttp = new CancelHttp()

export default {
  // testBaidu(data, config) {
  //   const http = action.commonPost('/api/json/data', data, config)
  //   http.mySourceKey = cancelHttp.add('testBaidu', http.mySource)
  //   return http
  // },
  quote(data, config) {
    return action.commonGet(process.env.VUE_APP_0X + 'swap/v1/quote', data, config)
  },
  tokens(config) {
    return action.commonGet(process.env.VUE_APP_0X + 'swap/v1/tokens', null, config)
  },
  send(data, config) {
    return action.commonPost(process.env.VUE_APP_RPC, data, config)
  },

  //swap
  swapQuote(params) {
    return action.commonGet(process.env.VUE_APP_0X + 'swap/v1/quote', params)
  },
  swapTokens(params) {
    return action.commonGet(process.env.VUE_APP_0X + 'swap/v1/tokens', params)
  },
  swapPrice(params) {
    return action.commonGet(process.env.VUE_APP_0X + 'swap/v1/price', params)
  },

  //coins
  coinsSimplePrice(params) {
    return action.commonGet(process.env.VUE_APP_COINGECKO + 'api/v3/simple/price', params)
  },
  coinsMarketChartRange(params, id) {
    return action.commonGet(process.env.VUE_APP_COINGECKO + `api/v3/coins/${id}/market_chart/range`, params)
  },
  coinsMarkets(params) {
    return action.commonGet(process.env.VUE_APP_COINGECKO + 'api/v3/coins/markets', params)
  },
  coinsMarketsContract(params) {
    return action.commonGet(
      process.env.VUE_APP_COINGECKO + `api/v3/coins/${params.id}/contract/` + params.contractAddress
    )
  },
  coinStakedInfo() {
    return action.commonGet('https://starscan.io/explorer-api/common/short/info')
  },

  //count down
  addressNum() {
    return action.commonGet('https://testnet-dex.functionx.io/activity/getBetaAddress')
  }
}
