<template>
  <div class="exchanges">
    <h2 class="exchanges-title">
      Listed Exchanges
    </h2>
    <div class="exchanges-list">
      <a class="exchanges-list-item" href="/" target="_blank" rel="noopener noreferrer" v-for="item in list" :key="item"
        ><i :class="item"></i
      ></a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Exchanges',
  data() {
    return {
      list: [
        'common-icon-exchanges-coinbase',
        'common-icon-exchanges-upbit',
        'common-icon-exchanges-bithumb',
        'common-icon-exchanges-htx',
        'common-icon-exchanges-kucoin',
        'common-icon-exchanges-uniswap',
        'common-icon-exchanges-osmosis'
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.exchanges {
  padding-top: 10px;
  padding-bottom: 245px;
}
.exchanges-title {
  text-align: center;
}
.exchanges-list {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}
.exchanges-list-item {
  display: block;
  width: 260px;
  padding: 14px 37px;
  border-radius: 4px;
  background-color: #fbfbfb;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 32px;
}
</style>
