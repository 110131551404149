<template>
  <div class="common-panel deploy" :style="styleObject">
    <div class="common-section deploy-content">
      <div class="common-transition-title-box single" ref="titleBox">
        <h2 class="common-title-circle lt deploy-title" ref="title">
          Deploy once across multi chains - FunctionX’s true omnichain feature
        </h2>
      </div>
      <div class="common-text deploy-text" ref="text">
        Deploy standard solidity contracts once and your dapps will work natively across different chains. Omnichain on
        FunctionX allows Defi dapps or NFT projects to work on Cosmos, Ethereum, L2s (Base, Arbitrum, etc) and Solana as
        if deployed on each chains, including native Metamask support - all through FunctionX omnichain.
      </div>
    </div>
    <div class="deploy-network" ref="network">
      <div class="deploy-network-bg">
        <div class="deploy-network-bg-item"></div>
        <div class="deploy-network-bg-item"></div>
        <div class="deploy-network-bg-item"></div>
      </div>
      <div class="deploy-network-circle">
        <svg
          width="1100px"
          height="310px"
          viewBox="0 0 1100 310"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <rect id="path-1" x="0" y="0" width="1100" height="310"></rect>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
              <stop stop-color="#131861" stop-opacity="0.12" offset="0%"></stop>
              <stop stop-color="#080A32" stop-opacity="0.6" offset="100%"></stop>
            </linearGradient>
            <path
              d="M550,216 C743.299662,216 900,175.705627 900,126 C900,76.2943725 743.299662,36 550,36 C356.700338,36 200,76.2943725 200,126 C200,175.705627 356.700338,216 550,216 Z"
              id="path-4"
            ></path>
            <mask
              id="mask-5"
              maskContentUnits="userSpaceOnUse"
              maskUnits="objectBoundingBox"
              x="0"
              y="0"
              width="700"
              height="180"
              fill="white"
            >
              <use xlink:href="#path-4"></use>
            </mask>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-6">
              <stop stop-color="#131861" stop-opacity="0.12" offset="0%"></stop>
              <stop stop-color="#080A32" stop-opacity="0.6" offset="100%"></stop>
            </linearGradient>
            <path
              d="M550,256 C793.00529,256 990,202.27417 990,136 C990,69.72583 793.00529,16 550,16 C306.99471,16 110,69.72583 110,136 C110,202.27417 306.99471,256 550,256 Z"
              id="path-7"
            ></path>
            <mask
              id="mask-8"
              maskContentUnits="userSpaceOnUse"
              maskUnits="objectBoundingBox"
              x="0"
              y="0"
              width="880"
              height="240"
              fill="white"
            >
              <use xlink:href="#path-7"></use>
            </mask>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-9">
              <stop stop-color="#131861" stop-opacity="0.12" offset="0%"></stop>
              <stop stop-color="#080A32" stop-opacity="0.6" offset="100%"></stop>
            </linearGradient>
            <path
              d="M550,310 C853.756612,310 1100,240.604136 1100,155 C1100,69.3958638 853.756612,0 550,0 C246.243388,0 0,69.3958638 0,155 C0,240.604136 246.243388,310 550,310 Z"
              id="path-10"
            ></path>
            <mask
              id="mask-11"
              maskContentUnits="userSpaceOnUse"
              maskUnits="objectBoundingBox"
              x="0"
              y="0"
              width="1100"
              height="310"
              fill="white"
            >
              <use xlink:href="#path-10"></use>
            </mask>
          </defs>
          <g id="img_circle" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="Rectangle"></g>
            <g
              id="Oval1"
              class="circle-line"
              :stroke-dasharray="circleDasharray[0]"
              :stroke-dashoffset="show ? circleLength[0] : 0"
              mask="url(#mask-2)"
              stroke="url(#linearGradient-3)"
              stroke-width="4"
            >
              <use mask="url(#mask-5)" xlink:href="#path-4"></use>
            </g>
            <g
              id="Oval2"
              class="circle-line"
              :stroke-dasharray="circleDasharray[1]"
              :stroke-dashoffset="show ? circleLength[1] : 0"
              mask="url(#mask-2)"
              stroke="url(#linearGradient-6)"
              stroke-width="3.87368421"
            >
              <use mask="url(#mask-8)" xlink:href="#path-7"></use>
            </g>
            <g
              id="Oval3"
              class="circle-line"
              :stroke-dasharray="circleDasharray[2]"
              :stroke-dashoffset="show ? circleLength[2] : 0"
              mask="url(#mask-2)"
              stroke="url(#linearGradient-9)"
              stroke-width="4"
            >
              <use mask="url(#mask-11)" xlink:href="#path-10"></use>
            </g>
          </g>
        </svg>
      </div>
      <div class="deploy-network-line">
        <svg
          width="1100px"
          height="310px"
          viewBox="0 0 1100 310"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>img_ray</title>
          <defs>
            <rect id="path-1" x="0" y="0" width="1100" height="310"></rect>
            <linearGradient x1="100%" y1="51.3030626%" x2="0%" y2="48.6969374%" id="line-linearGradient-1">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="61.6980742%" x2="100%" y2="38.3019258%" id="line-linearGradient-2">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="49.6588961%" x2="100%" y2="50.3411039%" id="line-linearGradient-3">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="50.1536636%" x2="100%" y2="49.8463364%" id="line-linearGradient-4">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="50.8242646%" x2="0%" y2="49.1757354%" id="line-linearGradient-5">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="48.9066595%" x2="0%" y2="51.0933405%" id="line-linearGradient-6">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="44.2055229%" x2="0%" y2="55.7944771%" id="line-linearGradient-7">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="1.73899661%" x2="100%" y2="98.2610034%" id="line-linearGradient-8">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="45.6747405%" x2="100%" y2="54.3252595%" id="line-linearGradient-9">
              <stop stop-color="#5B8FF9" stop-opacity="0.3" offset="0%"></stop>
              <stop stop-color="#5B8FF9" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <!-- in -->
            <linearGradient x1="100%" y1="51.3030626%" x2="0%" y2="48.6969374%" id="line-linearGradient-color-1">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="61.6980742%" x2="100%" y2="38.3019258%" id="line-linearGradient-color-2">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="49.6588961%" x2="100%" y2="50.3411039%" id="line-linearGradient-color-3">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="50.1536636%" x2="100%" y2="49.8463364%" id="line-linearGradient-color-4">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="50.8242646%" x2="0%" y2="49.1757354%" id="line-linearGradient-color-5">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="48.9066595%" x2="0%" y2="51.0933405%" id="line-linearGradient-color-6">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="100%" y1="44.2055229%" x2="0%" y2="55.7944771%" id="line-linearGradient-color-7">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="1.73899661%" x2="100%" y2="98.2610034%" id="line-linearGradient-color-8">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="45.6747405%" x2="100%" y2="54.3252595%" id="line-linearGradient-color-9">
              <stop stop-color="#5b8ff9" stop-opacity="1" offset="0%"></stop>
              <stop stop-color="#ff7dd4" stop-opacity="1" offset="100%"></stop>
            </linearGradient>
            <!-- out -->
          </defs>
          <g id="img_ray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <mask id="line-mask-2" fill="white">
              <use xlink:href="#path-1"></use>
            </mask>
            <g id="Rectangle"></g>
            <line
              v-for="(item, key) in line"
              :key="'bg' + key"
              x1="550"
              y1="96"
              :x2="item.x"
              :y2="item.y"
              :id="`line-path-${key + 1}`"
              :stroke="`url(#line-linearGradient-${key + 1})`"
              stroke-width="4"
              :stroke-dasharray="item.len"
              :stroke-dashoffset="show ? 0 : item.len"
              class="deploy-network-line-item-bg"
              mask="url(#line-mask-2)"
            ></line>
            <line
              v-for="(item, key) in line"
              :key="'in' + key"
              x1="550"
              y1="96"
              :x2="item.x"
              :y2="item.y"
              :id="`line-path-${key + 1}`"
              :stroke="`url(#line-linearGradient-color-${key + 1})`"
              stroke-width="4"
              :stroke-dasharray="item.len"
              :stroke-dashoffset="anime[key] === 1 ? item.len * 2 : item.len"
              class="deploy-network-line-item-in"
              :class="{ anime: anime[key] !== 0 }"
              mask="url(#line-mask-2)"
            ></line>
            <line
              v-for="(item, key) in line"
              :key="'out' + key"
              x1="550"
              y1="96"
              :x2="item.x"
              :y2="item.y"
              :id="`line-path-${key + 1}`"
              :stroke="`url(#line-linearGradient-color-${key + 1})`"
              stroke-width="4"
              :stroke-dasharray="item.len"
              :stroke-dashoffset="anime[key] === 2 ? -item.len : item.len"
              class="deploy-network-line-item-out"
              :class="{ anime: anime[key] !== 0 }"
              mask="url(#line-mask-2)"
            ></line>
          </g>
        </svg>
      </div>
      <div class="deploy-network-node-list">
        <div
          class="deploy-network-node"
          :class="[{ show: show }]"
          :style="{ left: item.left, top: item.top }"
          v-for="(item, key) in node"
          :key="item.title"
        >
          <div
            class="deploy-network-node-bg"
            :class="{ in: anime[key - 1] === 1, out: anime[key - 1] === 2, center: key === 0 && animeActive }"
          ></div>
          <div
            class="deploy-network-logo"
            :style="{ width: item.width }"
            :class="[item.type, { in: anime[key - 1] === 1 }]"
          >
            <i :class="item.logo"></i>
          </div>
          <div class="deploy-network-title font-bold" :style="{ bottom: item.bottom }">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'
import { ScrollTrigger } from '@/tools/gsap'

const SHOWLOGO = 2000
const SHOWLINE = 2000
const TIMELOGOBGRUN = 400
const TIMEIN = 500
const TIMEOUT = 1000
const TIMEAWAIT = 100

export default {
  name: 'Deploy',
  data() {
    return {
      show: false,
      animeTimer: 0,
      animeTimer2: 0,
      animeActive: false,
      circleLength: [1506, 1906, 2392],
      line: [
        { x: 327, y: 60, len: 225 },
        { x: 734, y: 7, len: 204 },
        { x: 889, y: 124, len: 340 },
        { x: 1019, y: 70, len: 469 },
        { x: 145, y: 44, len: 408 },
        { x: 9, y: 176, len: 546 },
        { x: 221, y: 208, len: 347 },
        { x: 664, y: 208, len: 159 },
        { x: 1026, y: 236, len: 496 }
      ],
      anime: [0, 0, 0, 0, 0, 0, 0, 0, 0], // 0 hide, 1 in, 2 out
      node: [
        {
          title: 'f(x)Core',
          logo: 'common-icon-chain-core',
          width: '168px',
          left: '466px',
          top: '12px',
          bottom: '-24px',
          type: 'main'
        },
        {
          title: 'Bitcoin Layer2',
          logo: 'common-icon-deploy-bitcoin',
          width: '88px',
          left: '284px',
          top: '16px',
          bottom: '-24px',
          type: ''
        },
        {
          title: 'Avalanche',
          logo: 'common-icon-deploy-avalanche',
          width: '56px',
          left: '706px',
          top: '-21px',
          bottom: '-28px',
          type: ''
        },
        {
          title: 'Solana',
          logo: 'common-icon-deploy-solana',
          width: '80px',
          left: '849px',
          top: '84px',
          bottom: '-24px',
          type: ''
        },
        {
          title: 'ETH',
          logo: 'common-icon-deploy-eth',
          width: '56px',
          left: '991px',
          top: '42px',
          bottom: '-28px',
          type: ''
        },
        {
          title: 'Base Chain',
          logo: 'common-icon-deploy-base',
          width: '56px',
          left: '117px',
          top: '16px',
          bottom: '-28px',
          type: ''
        },
        {
          title: 'Cosmos',
          logo: 'common-icon-deploy-cosmos',
          width: '64px',
          left: '-23px',
          top: '144px',
          bottom: '-28px',
          type: ''
        },
        {
          title: 'Arbitrum',
          logo: 'common-icon-deploy-arbitrum',
          width: '72px',
          left: '185px',
          top: '172px',
          bottom: '-28px',
          type: ''
        },
        {
          title: 'ETH Layer2',
          logo: 'common-icon-deploy-eth',
          width: '88px',
          left: '620px',
          top: '166px',
          bottom: '-24px',
          type: ''
        },
        {
          title: 'BNB Chain',
          logo: 'common-icon-deploy-bnb',
          width: '72px',
          left: '990px',
          top: '200px',
          bottom: '-28px',
          type: ''
        }
      ]
    }
  },
  computed: {
    circleDasharray() {
      return this.circleLength.map(item => {
        const num = Math.abs(item / (1 + 3))
        const list = []
        for (let i = 0; i < num; i++) {
          list.push(1)
          list.push(3)
        }
        return `0 ${item} ${list.join(' ')}`
      })
    },
    styleObject() {
      return {
        '--time-show-logo': SHOWLOGO + 'ms',
        '--time-show-line': SHOWLINE + 'ms',
        '--time-logo-bg-run': TIMELOGOBGRUN + 'ms',
        '--time-in': TIMEIN + 'ms',
        '--time-out': TIMEOUT + 'ms',
        '--time-await': TIMEAWAIT + 'ms'
      }
    }
  },
  mixins: [gsapDestroy],
  methods: {
    getRandomUniqueNumbers() {
      const min = 1
      const max = 8
      const count = Math.floor(Math.random() * 3) + 3 // Random count between 3 and 5
      const uniqueNumbers = new Set()

      while (uniqueNumbers.size < count) {
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
        uniqueNumbers.add(randomNumber)
      }

      return Array.from(uniqueNumbers)
    },
    setAnime() {
      const arr = this.getRandomUniqueNumbers()
      this.animeActive = true
      for (let i = 0; i < arr.length; i++) {
        if (i === 0) {
          this.$set(this.anime, arr[i], 1)
          continue
        }
        this.$set(this.anime, arr[i], 2)
      }
    },
    clearAnime() {
      this.animeActive = false
      for (let i = 0; i < this.anime.length; i++) {
        this.$set(this.anime, i, 0)
      }
    },
    runAnime() {
      this.setAnime()
      this.animeTimer = setTimeout(() => {
        this.clearAnime()
        this.animeTimer2 = setTimeout(() => {
          this.runAnime()
        }, TIMEAWAIT)
      }, TIMEIN + TIMEOUT)
    }
  },
  mounted() {
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: this.$refs.titleBox,
        start: 'bottom+=50 bottom',
        end: 'bottom+=50 bottom'
      },
      move: 'left'
    })
    this.commonGsapArray[1] = ScrollTrigger.create({
      trigger: this.$refs.network,
      start: 'bottom+=50px bottom',
      end: 'bottom+=50px bottom',
      once: true,
      onEnter: () => {
        this.show = true
        setTimeout(() => {
          this.runAnime()
        }, SHOWLOGO + SHOWLINE)
      }
    })
  },
  beforeDestroy() {
    if (this.animeTimer) {
      clearTimeout(this.animeTimer)
    }
    if (this.animeTimer2) {
      clearTimeout(this.animeTimer2)
    }
  }
}
</script>

<style scoped lang="scss">
.deploy {
  width: 100%;
  margin-top: 70px;
  padding-bottom: 150px;
}
.deploy-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.deploy-title {
  max-width: 650px;
}
.deploy-text {
  max-width: 414px;
}

.deploy-network {
  width: 1100px;
  height: 310px;
  margin-top: 121px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.deploy-network-bg,
.deploy-network-circle,
.deploy-network-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.deploy-network-bg-item {
  filter: blur(47.8px);
  position: absolute;
  &:nth-of-type(1) {
    top: 46px;
    left: 490px;
    width: 200px;
    height: 200px;
    background-color: #efe1ff;
  }
  &:nth-of-type(2) {
    top: 0;
    left: 498px;
    width: 136px;
    height: 136px;
    background-color: #e1fff7;
  }
  &:nth-of-type(3) {
    top: 16px;
    left: 410px;
    width: 200px;
    height: 200px;
    background-color: #c5d8ff;
  }
}

.deploy-network-node-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.deploy-network-node {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity var(--time-show-logo), transform var(--time-show-logo);
  &.show {
    opacity: 1;
    transform: translateY(0);
  }
}
.deploy-network-node-bg {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: scale(1);
  border-radius: 300px;
  background-image: radial-gradient(circle at 50% 50%, rgba(91, 143, 249, 0), #5b8ff9 71%);
  &.in {
    animation: var(--time-logo-bg-run) node-bg-show 0s;
  }
  &.out {
    animation: var(--time-logo-bg-run) node-bg-show calc(var(--time-in) + var(--time-out) / 2);
  }
  &.center {
    animation: var(--time-logo-bg-run) node-bg-show var(--time-in);
  }
}
.deploy-network-logo {
  position: relative;
  z-index: 2;
  &.main {
    padding: 13px;
  }
  &.in {
    animation: bounce 0.8s ease-in-out;
  }
}
.deploy-network-title {
  position: absolute;
  white-space: nowrap;
  background-color: #fbfbfb;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--main);
}

.circle-line {
  transition: stroke-dashoffset var(--time-show-logo);
}
.deploy-network-line-item-out.anime {
  transition: var(--time-out) stroke-dashoffset var(--time-in) linear;
}
.deploy-network-line-item-in.anime {
  transition: stroke-dashoffset var(--time-in) linear;
  animation: 0.3s line-hide var(--time-in) forwards;
}
.deploy-network-line-item-bg {
  transition: var(--time-show-line) stroke-dashoffset var(--time-show-logo);
}

@keyframes node-bg-show {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  70% {
    transform: scale(1.2);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes line-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  20% {
    transform: scale(1.3);
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
</style>
