<template>
  <div class="ScrollPage">
    <Bridge />
    <Ecosystem />
  </div>
</template>

<script>
import Bridge from '@/components/home/Bridge'
import Ecosystem from '@/components/home/Ecosystem'
export default {
  name: 'ScrollPage',
  components: { Ecosystem, Bridge }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';

.ScrollPage {
  width: 100%;
}
</style>
