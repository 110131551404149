const error = {
  // 登录
  200001: 'Wallet is not connected',

  210001: `Can't find MetaMask`,
  210002: `Can't connect to MetaMask`,
  210003: `MetaMask rejected to connect`,

  220003: `Can't connect to WalletConnect`,
  220004: `WalletConnect failed to connect to network`,
  220005: `WalletConnect failed to disconnect`,
  220006: `User closed WalletConnect QR code`,

  230001: `User denied transaction signature`,

  // 余额
  300001: `Can't get ETH balance`,
  300002: `Can't get ERC-20 balance`,

  // http
  100001: `Request failed`,
  100002: `Request timeout, please try again later`,
  100003: `Server did not respond`
}

class ErrCodeHandle {
  /**
   * 错误码统一处理 实例化
   * @param {function} commonFun - 通用处理方法
   */
  constructor(commonFun) {
    // 通用处理方法
    this.commonFun = commonFun
    // 例外错误码
    this.exceptCode = []
    // 特殊处理错误码
    this.specialCode = {}
  }
  /**
   * 例外不处理错误码
   * @param {array} codeList - 例外不处理错误码列表
   */
  except(codeList) {
    codeList.forEach(item => {
      this.exceptCode.push(String(item))
    })
  }
  /**
   * 增加特殊处理错误码
   * @param {string} code - 特殊处理错误码
   * @param {function} func - 特殊处理方法
   */
  on(code, func) {
    const useCode = String(code)
    if (typeof func !== 'function') {
      return
    }
    this.specialCode[useCode] = func
  }

  /**
   * 处理错误码
   * @param {string|number} code - 错误码
   */
  go(code) {
    const useCode = String(code)
    // 错误码是例外  不处理
    if (this.exceptCode.indexOf(useCode) !== -1) {
      return
    }
    // 如果是特殊错误码  特殊处理
    if (this.specialCode[useCode] !== undefined) {
      this.specialCode[useCode](useCode)
      return
    }
    // 通用处理
    this.commonFun(useCode)
  }
}

// 弹出错误码处理 可以联动i18next
function errMsgAlert(code) {
  let resMsg = ''
  if (error[code] !== undefined) {
    resMsg = error[code]
  }
  console.log(code, '>>>>>', resMsg)
}

/**
 * 抛出标准格式错误
 * @param {Number} code - 错误码
 * @return {{msg: *, code: *}}
 */
export function ErrorInfo(code) {
  let resMsg = ''
  if (error[code] !== undefined) {
    resMsg = error[code]
  }
  return {
    code: code,
    message: resMsg
  }
}

// 通用报错处理
export const errCodeHandle = new ErrCodeHandle(errMsgAlert)
