<template>
  <div class="CDotButton">
    <div
      class="cdb-dot"
      v-for="item of dotList"
      :key="item"
      @click="click(item)"
      :class="{ active: active === item }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'CDotButton',
  props: {
    num: {
      style: Number,
      default: 1
    },
    active: {
      style: Number,
      default: 0
    }
  },
  computed: {
    dotList() {
      const list = []
      for (let i = 0; i < this.num; i++) {
        list.push(i)
      }
      return list
    }
  },
  methods: {
    click(key) {
      if (key !== this.active) {
        this.$emit('update:active', key)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.CDotButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .cdb-dot {
    cursor: pointer;
    transition: height 0.3s;
    width: 6px;
    height: 12px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      right: 0;
      bottom: 3px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.072);
      transition: background-color 0.3s;
    }
    &.active {
      height: 22px;
      &:before {
        background-color: var(--font-link);
      }
    }
  }
}
</style>
