<template>
  <div class="foot" :class="{ dark: isDark }">
    <div class="content common-content">
      <div class="foot-copyright">
        Copyright &copy; 2018 - {{ new Date().getFullYear() }} Function X Foundation. All Rights Reserved.
      </div>
      <div class="foot-link">
        <template v-for="item of COMMUNITY">
          <div class="foot-link-item" :key="item.name" v-if="noShow.indexOf(item.name) === -1">
            <a :href="item.link" class="foot-link-item-a" target="_blank" rel="noopener noreferrer">
              <i :class="`common-icon-${item.icon}`"></i>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Foot',
  data() {
    return {
      isDark: false,
      noShow: ['FXDM', 'Product Release', 'Product Pipeline']
    }
  },
  computed: {
    ...mapState(['COMMUNITY'])
  },
  created() {
    this.$route.matched.forEach(item => {
      if (item.meta.footDark !== undefined) {
        this.isDark = true
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';
.foot {
  width: 100%;
  height: $footHeight;
  border-top: 1px solid rgba(8, 10, 50, 0.08);
  .content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .foot-copyright {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--font-sub);
  }
  .foot-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .foot-link-item {
    width: 32px;
    opacity: 0.2;
    transition: opacity 0.3s;
    cursor: pointer;
    position: relative;
    z-index: 1;
    &:hover {
      opacity: 0.5;
    }
    & + .foot-link-item {
      margin-left: 24px;
    }
    .foot-link-item-a {
      display: block;
      width: 100%;
    }
  }
  &.dark {
    background-color: var(--bg-color);
    border-color: rgba(255, 255, 255, 0.12);
    .foot-copyright {
      color: rgba(255, 255, 255, 0.5);
    }
    .foot-link-item-a > i {
      background-color: #fff;
    }
  }
}
</style>
