<template>
  <div class="case">
    <h2 class="common-title-circle lt case-title">DESIGNED FOR REAL WORLD USE CASE</h2>
    <div class="case-list">
      <div class="case-item" v-for="item in list" :key="item.title">
        <div
          class="case-item-img"
          :style="{ backgroundImage: `url('${require('@/assets/images/home/case/' + item.img)}')` }"
        >
          <video
            autoplay="autoplay"
            class="video"
            id="homevideoDiv"
            loop="loop"
            muted="muted"
            :src="showVideo ? require(`@/assets/video/${item.video}`) : ''"
          ></video>
        </div>
        <!--        <a :href="item.link" target="_blank" rel="noopener noreferrer" class="case-item-sub"-->
        <!--          >{{ item.sub }}-->
        <!--          <div class="case-item-icon">-->
        <!--            <i class="common-icon-jumpB"></i></div-->
        <!--        ></a>-->
        <!--        <h3 class="case-item-title">{{ item.title }}</h3>-->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Case',
  data() {
    return {
      showVideo: false,
      list: [
        { sub: 'CASE STUDY', title: 'XPOS', img: 'video_1.jpg', video: '1.mp4' },
        { sub: 'CASE STUDY', title: 'P(x)CARD', img: 'video_2.jpg', video: '2.mp4' },
        { sub: 'VIDEO', title: 'TITLE', img: 'video_3.jpg', video: '3.mp4' }
      ]
    }
  },
  methods: {
    handleLoad() {
      this.showVideo = true
    }
  },
  mounted() {
    // 检查页面是否已经加载完成
    if (document.readyState === 'complete') {
      // 页面已加载，直接设置变量
      this.handleLoad()
    } else {
      // 页面未加载完成，监听加载完成事件
      window.addEventListener('load', this.handleLoad, { once: true }) // 使用 once 选项自动移除监听器
    }
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器，确保不泄漏
    window.removeEventListener('load', this.handleLoad)
  }
}
</script>

<style scoped lang="scss">
.case {
  padding: $casePaddingTop 32px 0px;
  width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
}
.case-title {
  line-height: $caseTitleHeight;
}
.case-list {
  margin-top: $caseListTop;
  width: 100%;
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: $caseItemGap;
  grid-template-rows: $caseItemHeight $caseItemHeight;
  grid-template-columns: 746px auto;
}
.case-item {
  position: relative;
  pointer-events: none;
  &:nth-of-type(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
}
.case-item-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.case-item-sub {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 13px;
  font-size: 14px;
  background-color: #fbfbfb;
  opacity: 0.86;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
    color: initial;
  }
}
.case-item-icon {
  width: 16px;
  margin-left: 4px;
}
.case-item-title {
  margin-top: 24px;
}
</style>
