import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

// import { UAParser } from 'ua-parser-js'

Vue.use(VueRouter)

// const ua = new UAParser()
// const browser = ua.getBrowser().name

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { headDark: true }
  },
  {
    path: '/derivatives',
    name: 'Derivatives',
    component: () => import(/* webpackChunkName: "derivatives" */ '../views/Derivatives.vue')
  },
  {
    path: '/derivatives/variable',
    name: 'Variable',
    component: () => import(/* webpackChunkName: "variable" */ '../views/Variable.vue'),
    meta: { headDark: true, footDark: true }
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component: () => import(/* webpackChunkName: "JoinUs" */ '../views/JoinUs.vue')
  },
  {
    path: '/fxwallet',
    name: 'Wallet',
    component: () => import(/* webpackChunkName: "Wallet" */ '../views/Wallet.vue')
  },
  {
    path: '/ecosystem',
    name: 'Ecosystem',
    component: () => import(/* webpackChunkName: "Ecosystem" */ '../views/Ecosystems.vue')
  }
]

// 测试环境 可以显示test页面
if (process.env.VUE_APP_MODE === 'test') {
  routes.push({
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
  })
}

// 不存在页面  跳回首页
routes.push({
  path: '*',
  redirect: '/home'
})

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  routes,
  scrollBehavior(to) {
    // if (to.hash && browser === 'Safari') {
    //   // 兼容 safari 不能滑动滚动到锚点问题
    //   console.log('hash', to.hash)
    //   console.log('dom', document.querySelector(to.hash))
    //   const rect = document.querySelector(to.hash).getBoundingClientRect()
    //   window.scrollBy({
    //     top: rect.y,
    //     behavior: 'smooth'
    //   })
    // } else
    if (to.hash) {
      return {
        selector: to.hash,
        // offset: { x: 0, y: 0 },
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0)
  store.commit('setBridgeMask', 0)
  store.commit('setBridgeScrollEnd', true)
})

export default router
