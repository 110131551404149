<template>
  <div class="default">
    <Head />
    <slot></slot>
    <Foot />
    <CPopForm />
  </div>
</template>

<script>
import Head from '@/components/layout/Head'
import Foot from '@/components/layout/Foot'
import CPopForm from '@/components/home/CPopForm'

export default {
  name: 'Default',
  components: { CPopForm, Foot, Head }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';

.default {
  width: 100%;
  min-width: 1200px;
  position: relative;
  //padding-top: $headHeight;
}
</style>
