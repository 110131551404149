import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    wallet: null,
    download: [
      {
        icon: 'common-icon-ios',
        title: 'iOS',
        note: 'App Store',
        placement: 'top',
        src: '',
        link: 'https://apps.apple.com/us/app/f-x-wallet-by-pundi-x-labs/id1504798360'
      },
      {
        icon: 'common-icon-android',
        title: 'Android',
        note: 'Google Play',
        placement: 'top',
        src: '',
        link: 'https://play.google.com/store/apps/details?id=com.pundix.functionx'
      }
    ],
    downloadAPK: 'https://download.functionx.io/app/Android/f(x)Wallet_release.apk',
    COMMUNITY: [
      {
        icon: 'monday',
        name: 'Product Pipeline',
        link: 'https://view.monday.com/2417712327-86b14f3d4fc23cb0e32010beceab8834?r=use1'
      },
      {
        icon: 'monday',
        name: 'Product Release',
        link: 'https://view.monday.com/2471253229-cdc7b39b4774964e3c81e414c7326713?r=use1'
      },
      { icon: 'web', name: 'FXDM', link: 'https://www.fxdm.functionx.io/' },
      { icon: 'forum', name: 'Forum', link: 'https://forum.starscan.io' },
      { icon: 'telegram', name: 'Telegram', link: 'https://t.me/functionx' },
      {
        icon: 'youtube',
        name: 'YouTube',
        link: 'https://www.youtube.com/channel/UCmS83bWNR43jwEOC6EJaOyw'
      },
      {
        icon: 'medium',
        name: 'Medium',
        link: 'https://medium.com/functionx'
      },
      {
        icon: 'facebook',
        name: 'Facebook',
        link: 'https://facebook.com/FunctionX.io/'
      },
      {
        icon: 'twitter',
        name: 'Twitter',
        link: 'http://twitter.com/functionx_io'
      },
      // {
      //   icon: 'discord',
      //   name: 'Discord',
      //   link: 'https://discord.com/invite/J6k8arnyEQ'
      // },
      { icon: 'github', name: 'Github', link: 'https://github.com/functionx' },
      { icon: 'email', name: 'Email', link: 'mailto:contact@functionx.io' },
      {
        icon: 'instagram',
        name: 'Instagram',
        link: 'https://instagram.com/functionx_official'
        // },
        // {
        //   icon: 'linkedin',
        //   name: 'Linkedin',
        //   link: 'https://www.linkedin.com/showcase/functionx'
      }
      // { icon: 'rss', name: 'RSS', link: '' },
    ],
    ecosystemData: {
      Blockchain: [
        { name: 'BNBChain', url: 'https://www.bnbchain.org/en', logo: 'logo_bnbchain.png' },
        { name: 'Bitcoin', url: 'https://bitcoin.org/en/', logo: 'logo_bitcoin.png' },
        { name: 'Ethereum', url: 'https://ethereum.org/en/', logo: 'logo_ethereum.png' },
        { name: 'Avalanche', url: 'https://www.avax.network/', logo: 'logo_avalanche.png' },
        { name: 'Arbitrum', url: 'https://arbitrum.foundation/', logo: 'logo_arbitrum.png' },
        { name: 'Optimism', url: 'https://www.optimism.io/', logo: 'logo_optimism.png' },
        { name: 'Polygon', url: 'https://polygon.technology/', logo: 'logo_polygon.png' },
        { name: 'Tron', url: 'https://trondao.org/', logo: 'logo_tron.png' },
        { name: 'Pundi X Chain', url: 'https://preview.pundix.com/', logo: 'logo_pudinxchain.png' },
        { name: 'f(x)Core', url: 'https://www.functionx.io ', logo: 'logo_f(x)core.png' },
        { name: 'Solana', url: 'https://solana.com/', logo: 'logo_solana.png' },
        { name: 'Cosmos', url: 'https://cosmos.network/', logo: 'logo_cosmos.png' },
        { name: 'Osmosis', url: 'https://osmosis.zone/ ', logo: 'logo_osmosis.png' }
      ],
      Analytics: [
        { name: 'Starscan', url: 'https://starscan.io/', logo: 'logo_starscan.png' },
        { name: 'Map of Zones', url: 'https://mapofzones.com/zones/fxcore/overview', logo: 'logo_mapofzones.png' },
        { name: 'Ping Pub', url: 'https://ping.pub/fxcore', logo: 'logo_pingpub.png' },
        { name: 'Alphaday', url: 'https://app.alphaday.com/b/alpha/', logo: 'logo_alphaday.png' },
        { name: 'Defillama', url: 'https://defillama.com/chain/FunctionX', logo: 'logo_defillama.png' },
        { name: 'DeBank', url: 'https://debank.com/', logo: 'logo_debank.png' }
      ],
      DeFi: [
        // { name: 'FXSwap', url: 'https://fx-swap.io/', logo: 'logo_fxswap.png' },
        { name: 'MarginX', url: 'https://marginx.io/', logo: 'logo_marginx.png' },
        { name: 'Baklava Space', url: 'https://baklava.space/', logo: 'logo_baklavaspace.png' },
        { name: 'Paxos', url: 'https://paxos.com/', logo: 'logo_paxos.png' }
      ],
      Institution: [
        { name: 'Asosiasi Blockchain Indonesia', url: 'https://asosiasiblockchain.co.id/', logo: 'logo_asosiasi.png' },
        { name: 'Litecoin Foundation', url: 'https://www.litecoin.net/', logo: 'logo_litecoin.png' },
        { name: 'European University Cyprus', url: 'https://euc.ac.cy/en/', logo: 'logo_european.png' },
        { name: 'Blockchain Collaborative Consortium', url: 'https://bccc.global/', logo: 'logo_bccc.png' },
        { name: 'Nobi', url: 'https://usenobi.com/en/home-en/', logo: 'logo_nobi.png' },
        { name: 'Kronos Research', url: 'https://kronosresearch.com/', logo: 'logo_kronos.png' },
        { name: 'Infineon', url: 'https://www.infineon.com/', logo: 'logo_infineon.png' }
      ],
      Interoperability: [
        { name: 'f(x)Bridge', url: 'https://starscan.io/fxbridge', logo: 'logo_f(x)bridge.png' },
        { name: 'Wanchain Bridge', url: 'https://bridge.wanchain.org/', logo: 'logo_wanchain.png' },
        { name: 'Supra Oracles', url: 'https://supraoracles.com/', logo: 'logo_supra.png' }
      ],
      NFT: [{ name: 'Fox Gaming', url: 'https://www.officialfoxcoin.com/', logo: 'logo_foxgaming.png' }],
      Social: [
        { name: 'Zealy', url: 'https://zealy.io/c/functionx/questboard', logo: 'logo_zealy.png' },
        { name: 'ContributionDAO', url: 'https://contributiondao.com/', logo: 'logo_contribution.png' }
      ],
      Stablecoin: [
        { name: 'Circle (USDC)', url: 'https://www.circle.com/en/', logo: 'logo_usdc.png' },
        { name: 'Tether (USDT)', url: 'https://tether.to/', logo: 'logo_usdt.png' },
        { name: 'Maker DAO (DAI)', url: 'https://makerdao.com/en/', logo: 'logo_maker.png' },
        { name: 'Stasis (EURS)', url: 'https://eurs.stasis.net/', logo: 'logo_stasis.png' },
        { name: 'Frax', url: 'https://frax.finance/', logo: 'logo_frax.png' },
        { name: 'Binance USD (BUSD)', url: 'https://paxos.com/busd/', logo: 'logo_busd.png' }
      ],
      Wallet: [
        { name: 'Coin98', url: 'https://coin98.com/', logo: 'logo_coin98.png' },
        { name: 'f(x)Wallet', url: 'https://download.functionx.io/', logo: 'logo_fxwallet.png' },
        { name: 'Keplr', url: 'https://www.keplr.app/', logo: 'logo_keplr.png' },
        { name: 'Metamask', url: 'https://metamask.io/', logo: 'logo_metamask.png' },
        { name: 'WalletConnect', url: 'https://walletconnect.com/', logo: 'logo_walletconnect.png' },
        { name: 'Teleport', url: 'https://teleport.network/', logo: 'logo_teleport.png' },
        { name: 'p(x)Card', url: 'https://pxcard.io', logo: 'logo_pxcard.png' },
        { name: 'Rabby', url: 'https://rabby.io/', logo: 'logo_rabby.png' }
      ],
      Lifestyle: [{ name: 'Ultra', url: 'https://ultrataiwan.com/zh-tw/', logo: 'logo_Ultra.png' }]
    },
    bridgeResourceReady: false,
    navTransitionDelay: true,
    bridgeScrollEnd: true,
    bridgeMask: 0
  },
  mutations: {
    setWallet(state, payload) {
      state.wallet = payload
    },
    setBridgeResourceReady(state, payload) {
      state.bridgeResourceReady = payload
    },
    setNavTransitionDelay(state, payload) {
      state.navTransitionDelay = payload
    },
    setBridgeScrollEnd(state, payload) {
      state.bridgeScrollEnd = payload
    },
    setBridgeMask(state, payload) {
      state.bridgeMask = payload
    }
  },
  actions: {},
  modules: {}
})
