<template>
  <a :href="downloadAPK" target="_blank" rel="noopener noreferrer" class="text-download-text font-bold">
    <div class="text-download-item-icon" :style="{ 'margin-right': margin }">
      <i class="common-icon-android-dre"></i>
    </div>
    <span class="text-download-item-text">Direct Download for Android</span>
  </a>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TextDownload',
  props: {
    margin: {
      style: String,
      default: '8px'
    }
  },
  computed: {
    ...mapState(['downloadAPK'])
  }
}
</script>

<style scoped lang="scss">
.text-download-text {
  font-size: 14px;
  color: var(--main) !important;
  line-height: 14px;
  font-weight: 500;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .text-download-item-icon {
    width: 24px;
    //margin-right: 8px;
  }

  .text-download-item-text {
    position: relative;
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border-bottom: 1px solid #080a32;
      transform-origin: 0 0;
      transform: scale(0.5);
    }
  }
}
</style>
