<template>
  <div class="Team">
    <div class="tm-bg">
      <transition name="transition-img-bg" v-for="(item, key) of team" :key="key">
        <div class="tm-bg-img" v-show="infoActiveNum === key">
          <img :src="require('@/assets/images/home/team/' + item.bg)" alt="" />
        </div>
      </transition>
    </div>
    <div class="tm-context">
      <div class="common-transition-title-box single" ref="titleBox">
        <transition name="transition-title">
          <h2 class="common-title-circle lt tm-title" ref="title">
            Team
          </h2>
        </transition>
      </div>
      <transition name="transition-text-b">
        <div class="common-text tm-note" ref="text"></div>
      </transition>
    </div>
    <div class="tm-toolbar">
      <div
        class="tm-toolbar-bg"
        :style="{ width: toolBarWidth[groupActive] + 'px', transform: `translateX(${toolbarX}px)` }"
      ></div>
      <div
        class="tm-toolbar-text font-bold"
        :class="[{ active: key === groupActive }, 'tm-toolbar-text-' + key]"
        v-for="(item, key) of teamTab"
        :key="key"
        @click="changeGroupActive(key)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="tm-content">
      <div class="tm-details">
        <div class="tm-details-portrait-box">
          <div class="tm-details-portrait">
            <transition name="transition-img" v-for="(item, key) of team" :key="key">
              <div class="tm-details-portrait-img" v-show="infoActiveNum === key">
                <img :src="require('@/assets/images/home/team/' + item.portrait)" alt="" />
              </div>
            </transition>
          </div>
        </div>
        <div class="tm-details-context">
          <transition name="list" v-for="(item, key) of team" :key="key">
            <div class="tm-details-context-text" v-show="key === infoActiveNum">
              <h4 class="font-bold tm-details-name">
                {{ item.name }}
              </h4>
              <div class="tm-position">{{ item.position }}</div>
              <div class="common-text tight tm-details-note">
                {{ item.describe }}
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="tm-btns">
        <CDotButton :num="teamTab[groupActive].num" :active.sync="dotActive" />
      </div>
      <div class="tm-list">
        <div class="tm-list-point"></div>
        <div class="tm-list-bg"></div>
        <div class="tm-list-content" :style="{ transform: `translateY(-${listTop}px)` }">
          <div
            class="tm-list-item"
            v-for="(item, key) of teamScrollList"
            :key="key"
            :class="{ active: key - 2 === infoActiveNum }"
            @click="changeActive(item.key)"
          >
            <div class="tm-list-portrait">
              <img :src="require('@/assets/images/home/team/' + item.portrait)" alt="" />
            </div>
            <div class="tm-list-context">
              <h5 class="font-bold tm-list-name">{{ item.name }}</h5>
              <div class="tm-position small">{{ item.position }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CDotButton from '@/components/common/CDotButton'
import { gsap } from '@/tools/gsap'
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'

const LISTITEMHEIGHT = 100
const MOREITEMNUM = 2

export default {
  name: 'Team',
  data() {
    return {
      listTop: MOREITEMNUM * LISTITEMHEIGHT,
      infoActive: 0,
      toolBarWidth: [],
      teamTab: [
        {
          name: 'Council',
          num: 3
        },
        {
          name: 'Sub-council for technology',
          num: 3
        },
        {
          name: 'Sub-council for marketing (FXDM)',
          num: 6
        }
      ],
      team: [
        {
          portrait: '01.png',
          bg: '01_bg.jpg',
          name: 'David Ben Kay',
          position: 'President',
          describe:
            "As the President of Function X, David leads the foundation’s efforts to create an ecosystem that is wholly powered by blockchain technology. Previously, he was the Ethereum Foundation's governing board member, General Counsel of Microsoft China, and Managing Partner of Denton Hall, Beijing. David received his JD from UCLA."
        },
        {
          portrait: '02.png',
          bg: '02_bg.jpg',
          name: 'Yos Adiguna Ginting',
          position: 'Council Member',
          describe:
            'Yos is currently the Chair of the Board of Supervisors at Asosiasi Blockchain Indonesia, the country’s official organization of blockchain-powered companies. He also chairs the Board of Directors at the Indonesia Services Dialogue Council as well as the University Council of Sampoerna University.'
        },
        // {
        //   portrait: '03.png',
        //   bg: '03_bg.jpg',
        //   name: 'Yoshiyuki Yamamoto',
        //   position: 'Council Member',
        //   describe:
        //     'Yoshi currently serves as a council member at the Function X Foundation. He brings a wealth of expertise and experience in the realms of blockchain that he gained during his career in the United Nations System, and works towards bringing solutions that would make substantial improvements to the society. He aims at humanising blockchain technology with his valuable contribution to the Function X Foundation.'
        // },
        {
          portrait: '04.png',
          bg: '04_bg.jpg',
          name: 'Indra Winarta',
          position: 'Ecosystem Lead',
          describe:
            'Indra was an ex-Consultant in a sub-company of DCI Indonesia (the leading company with the highest technology market capitalization in Indonesia) and focused in business process improvement and reengineering projects in the Southeast Asia region. He joined Pundi X as one of the first batch of employees and was in-charge of token listing. He graduated with a B.Sc in Applied Mathematics and BEng in Computer Science from Universitas Pelita Harapan. Indra was selected as Indonesian youth delegate in the APEC Summit.'
        },
        {
          portrait: '05.png',
          bg: '05_bg.jpg',
          name: 'Zac Cheah',
          position: 'Sub Council Member',
          describe:
            'Zac is the CEO of Pundi X and contributes to Function X as a founding member and was Chair of a W3C Interest Group. He was an EU scholar in M.Sc Computing (Sweden KTH) and M.Sc Security (Norway NTNU). He was a national coding champion in high school.'
        },
        {
          portrait: '06.png',
          bg: '06_bg.jpg',
          name: 'FrenchXCore',
          position: 'Sub Council Member',
          describe:
            "FrenchXCore runs the FrenchXCore validator node on the FunctionX blockchain. Acting as innovation director in an important public entity in France, his past professional background is in telecommunications (wired and radio), networks, information systems, cyber projects and team management. He's personally very enthusiastic about blockchains and their applications in the real world, particularly as an automated trusted authority. He spends most of his free time programming cool stuff (Java, Python, assembler)."
        },
        {
          portrait: '07.png',
          bg: '07_bg.jpg',
          name: 'Rafal W (kenorb)',
          position: 'Sub Council Member',
          describe:
            'Rafal W is an IT professional specializing in web, cloud, trading and blockchain technologies. As a contractor, he worked for several projects for clients including PwC, CapGemini, UBS AG). Currently at R3 responsible for delivering, deploying and maintaining infrastructure for Corda, a decentralized database based on blockchain technology for enterprise clients across the globe.'
        },
        // {
        //   portrait: '08.png',
        //   bg: '08_bg.jpg',
        //   name: 'Richard Ng',
        //   position: 'Sub Council Member',
        //   describe:
        //     "Richard leads the blockchain DevOps at Pundi X. He is both a programmer and a quantitative trader. In Function X, he is active in the Ecosystem Genesis Fund technology grant selection panel. He graduated with a BA in Quantitative Finance from Singapore Management University and was Singapore's national swimmer."
        // },
        {
          portrait: '09.png',
          bg: '09_bg.jpg',
          name: 'Andreas Harpas',
          position: 'Sub-Council Member',
          describe:
            'Andreas is a growth manager at Function X and leads the FXDM (Function X Decentralized Marketing) efforts at Function X whereby marketing grants are given out to marketing grant applicants and rewards given to creative endeavors within the community. He has a B.Sc in Human Biology and an M.Sc in Bio-Business at Birkbeck University of London.'
        },
        {
          portrait: '10.png',
          bg: '10_bg.jpg',
          name: 'Judie Liu',
          position: 'Sub-Council Member',
          describe:
            'Judie is the Marketing Manager at Pundi X. She has 10 years of experience in multi-national startups including E-Commerce, mobile Apps, online & offline marketing and blockchain. She is the co-founder of The Mentorship Program of Girls In Tech Taiwan and has received her Bachelor Degree of Journalism from National ChengChi University.'
        },
        {
          portrait: '11.png',
          bg: '11_bg.jpg',
          name: 'Telchar',
          position: 'Sub-Council Member',
          describe:
            'Telchar has been in crypto since 2011 following along through the various cycles and finally getting more invested both in time and value in the 2017 cycle. He has been in the mining space for BTC and Eth and has run a home mine. He is a libertarian by nature and believes in a world of free choices. He is a FXNEER at FXDM where he reviews the submissions and was instrumental in formulating the grading guidelines.'
        },
        {
          portrait: '12.png',
          bg: '12_bg.jpg',
          name: 'Cryptowars',
          position: 'Sub-Council Member',
          describe:
            'Cryptowars has experience in hotel management. He has been a Function X supporter from the beginning and one of FXNEERs at FXDM where he shares his feedback and participates in grading the content submitted by creators.'
        },
        {
          portrait: '13.png',
          bg: '13_bg.jpg',
          name: 'Claudio Barros',
          position: 'Sub-Council Member',
          describe:
            'Claudio has been a Pundi X supporter since 2018 and is running a Function X Validator (ClaudioメBarros). He is a crypto and technology enthusiast.'
        },
        {
          portrait: '14.png',
          bg: '14_bg.jpg',
          name: 'SCENE',
          position: 'Sub-Council Member',
          describe:
            'SCENE has been an early supporter of Pundi X and Function X since genesis and has been following the projects till now. He participates as a FXNEER at FXDM to review submissions.'
        }
      ]
    }
  },
  components: { CDotButton },
  mixins: [gsapDestroy],
  computed: {
    dotActive: {
      set(val) {
        this.infoActive = this.beforeActiveNum + val
      },
      get() {
        return this.infoActiveNum - this.beforeActiveNum
      }
    },
    beforeActiveNum() {
      const list = this.teamTab.slice(0, this.groupActive)
      let total = 0
      list.forEach(item => {
        total += item.num
      })
      return total
    },
    // 返回纯正数
    infoActiveNum() {
      if (this.infoActive < 0) {
        return -1 - this.infoActive
      }
      return this.infoActive
    },
    // 给team数据增加key
    teamScrollList() {
      const keyTeam = this.team.map((item, key) => {
        const newItem = { ...item }
        newItem.key = key
        return newItem
      })
      const newList = [...keyTeam]
      for (let i = 0; i < MOREITEMNUM; i++) {
        const newKeyItem = JSON.parse(JSON.stringify(keyTeam[i]))
        newKeyItem.key = -1 - i
        newList.push(newKeyItem)
        newList.unshift(keyTeam[keyTeam.length - 1 - i])
      }
      return newList
    },
    toolbarX() {
      const list = this.toolBarWidth.slice(0, this.groupActive)
      const num = list.reduce((total, num) => {
        return total + num + 4
      }, 0)
      return num
    },
    groupList() {
      const tabKeyList = []
      let nowNum = 0
      this.teamTab.forEach(item => {
        const list = []
        for (let i = nowNum; i < nowNum + item.num; i++) {
          list.push(i)
        }
        tabKeyList.push(list)
        nowNum += item.num
      })
      return tabKeyList
    },
    groupActive() {
      for (let i = 0; i < this.groupList.length; i++) {
        if (this.groupList[i].indexOf(this.infoActive) !== -1) {
          return i
        }
      }
      return 0
    }
  },
  watch: {
    infoActive(newVal, oldVal) {
      let top
      let start
      if (newVal < 0) {
        start = (MOREITEMNUM - this.team.length + oldVal) * LISTITEMHEIGHT
        top = (MOREITEMNUM - 1 - newVal) * LISTITEMHEIGHT
      } else {
        if (oldVal < 0) {
          start = (MOREITEMNUM - 1 - oldVal) * LISTITEMHEIGHT
        } else {
          start = (oldVal + MOREITEMNUM) * LISTITEMHEIGHT
        }
        top = (newVal + MOREITEMNUM) * LISTITEMHEIGHT
      }
      gsap.fromTo(
        this.$data,
        {
          listTop: start
        },
        {
          duration: 0.3,
          listTop: top
        }
      )
    }
  },
  methods: {
    changeActive(key) {
      this.infoActive = key
    },
    changeGroupActive(key) {
      this.infoActive = this.groupList[key][0]
    }
  },
  mounted() {
    document.fonts.ready.then(() => {
      for (let i = 0; i < this.team.length; i++) {
        const bar = document.querySelector('.tm-toolbar-text-' + i)
        if (bar !== null) {
          this.toolBarWidth.push(bar.clientWidth)
        }
      }
      this.commonGsapArray[0] = animeTitleShow({
        titleEl: this.$refs.title,
        textEl: this.$refs.text,
        scrollTrigger: {
          trigger: this.$refs.titleBox,
          start: 'bottom+=50 bottom',
          end: 'bottom+=50 bottom'
        }
      })
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';
@import '~@/assets/style/variable.scss';

.Team {
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 35px 0 16px 32px;
  .tm-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 36px;
    z-index: -1;
    overflow: hidden;
    .tm-bg-img {
      position: absolute;
      left: -366px;
      bottom: -255px;
      width: 1056px;
      //filter: blur(20px);
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .tm-toolbar {
    position: absolute;
    top: 40px;
    right: 56px;
    height: 48px;
    padding: 4px;
    background-color: #fafafa;
    border-radius: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .tm-toolbar-bg {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 40px;
    width: 40px;
    border: 0.5px solid rgba(5, 19, 46, 0.08);
    box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
    border-radius: 20px;
    background-color: #fff;
    transition: width 0.3s, transform 0.3s;
  }
  .tm-toolbar-text {
    position: relative;
    height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.2;
    transition: opacity 0.3s;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    &:hover {
      opacity: 0.5;
    }
    &.active {
      opacity: 1;
    }
    & + .tm-toolbar-text {
      margin-left: 4px;
    }
  }
  .tm-context {
    margin-left: 62px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .tm-title {
    padding-bottom: $transitionTitleBottom;
    padding-right: 10px;
  }
  .tm-note {
    margin-top: 8px;
    text-align: center;
  }
  .tm-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 27px;
  }
  .tm-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 77px;
  }
  .tm-details-portrait-box {
    flex-shrink: 0;
    margin-left: 66px;
    width: 128px;
    height: 128px;
    border: 2px solid var(--main);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      z-index: -1;
      @include position-all;
      @include point-shadow;
      border-radius: 100px;
      transform: translate(-6px, 6px);
    }
  }
  .tm-details-portrait {
    border: 2px solid #ffffff;
    border-radius: 128px;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #fff;
    .tm-details-portrait-img {
      @include position-all(0px);
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .tm-details-context {
    margin-left: 32px;
    width: 482px;
    height: 290px;
    position: relative;
    overflow: hidden;
  }
  .tm-details-context-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .tm-details-name {
  }
  .tm-position {
    font-size: 15px;
    line-height: 24px;
    &.small {
      font-size: 14px;
    }
  }
  .tm-details-note {
    margin-top: 8px;
  }
  .tm-btns {
    margin-top: 64px;
    margin-left: auto;
    margin-right: -16px;
    position: relative;
    z-index: 2;
  }
  .tm-list {
    height: 292px;
    overflow: hidden;
    margin: 56px 0 32px;
    padding-left: 32px;
    padding-right: 56px;
    position: relative;
    z-index: 1;
    .tm-list-bg {
      position: absolute;
      top: 0;
      left: 32px;
      height: 92px;
      width: 340px;
      border-radius: 4px;
      border: 2px solid var(--main);
      z-index: -1;
      background-color: #fff;
    }
    .tm-list-point {
      @include position-all();
      @include point-shadow;
      z-index: -1;
      left: 32px;
      height: 92px;
      width: 340px;
      border-radius: 4px;
      transform: translate(4px, 4px);
    }
    .tm-list-content {
    }
  }
  .tm-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 340px;
    min-height: 92px;
    padding-left: 16px;
    position: relative;
    transition: opacity 0.3s;

    & + .tm-list-item {
      margin-top: 8px;
    }
    &.active {
      transform: translateZ(0);
    }
    &:not(.active) {
      opacity: 0.2;
      cursor: pointer;
    }
    &:not(.active):hover {
      opacity: 0.5;
    }
  }
  .tm-list-portrait {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    img {
      display: block;
      width: 100%;
    }
  }
  .tm-list-context {
    margin-left: 12px;
  }
  .list-enter {
    transform: translateX(-100%);
  }
  .list-enter-active {
    transition: transform 0.3s;
  }
  .list-enter-to {
    transform: translateX(0%);
  }
  .list-leave {
    opacity: 1;
  }
  .list-leave-active {
    transition: opacity 0.3s;
  }
  .list-leave-to {
    opacity: 0;
  }
}
</style>
