import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'view-design/dist/styles/iview.css'

import '@/assets/style/base.scss'
import '@/assets/style/icon.scss'
import '@/assets/style/font.scss'
import '@/assets/style/common.scss'
import '@/assets/style/ivu.scss'

// import ECharts from 'vue-echarts'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/component/grid'
// import 'echarts/lib/component/tooltip'

import {
  Slider,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Progress,
  Poptip
} from 'view-design'
Vue.component('iSlider', Slider)
Vue.component('iDropdown', Dropdown)
Vue.component('iDropdownMenu', DropdownMenu)
Vue.component('iDropdownItem', DropdownItem)
Vue.component('iProgress', Progress)
Vue.component('iPoptip', Poptip)
// Vue.component('v-chart', ECharts)

import '@/assets/style/icon.scss'
import '@/assets/style/transition.scss'

import loading from './plugins/loading'
Vue.use(loading)

// import Highcharts from 'highcharts'
// import HighchartsVue from 'highcharts-vue'
// import stockInit from 'highcharts/modules/stock'
//
// stockInit(Highcharts)
// Vue.use(HighchartsVue)
//
// Highcharts.setOptions({
//   lang: {
//     rangeSelectorZoom: '' // 不显示 'zoom' 文字
//   }
// })

Vue.config.productionTip = false

window.vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
