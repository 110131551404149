export const imgLoadError = {
  methods: {
    imgError(e) {
      e.target.style.display = 'none'
    },
    imgErrorSrc(e) {
      e.target.src = require('@/assets/images/icon/fail@2x.png')
    }
  }
}

export const positiveTimingMixins = {
  data() {
    return {
      // 正计时数字
      positiveTimingNum: 0,
      // 计时器
      timer: '',
      // 分钟
      minutes: 0
    }
  },
  methods: {
    // 开始正计时
    positiveTimingStart() {
      this.positiveTiming(this.positiveTimingNum)
    },
    // 重置正计时
    positiveTimingClear() {
      // 销毁计时器
      clearTimeout(this.timer)
    },
    // 正计时
    positiveTiming(num) {
      this.timer = setTimeout(() => {
        // 回调计时数加1
        this.positiveTiming(num + 1)
      }, 1000)
      if (num >= 60) {
        num = 0
        this.minutes = this.minutes + 1
      }

      this.positiveTimingNum =
        (this.minutes < 10 ? '0' + this.minutes : this.minutes) +
        ':' +
        (num < 10 ? '0' + num : num)
    }
  },
  beforeDestroy() {
    // 重置正计时
    this.positiveTimingClear()
  }
}

export const countdownMixins = {
  data() {
    return {
      // 倒计时数字
      countdownNum: 0,
      // 计时器
      timer: '',
      // 倒计时最大数常量
      COUNTDOWNMAXNUM: 30
    }
  },
  methods: {
    // 开始倒计时
    countdownStart() {
      this.countdown(this.COUNTDOWNMAXNUM)
    },
    // 重置倒计时
    countdownClear() {
      // 销毁计时器
      clearTimeout(this.timer)
    }
  },
  beforeDestroy() {
    // 重置倒计时
    this.countdownClear()
  }
}

export const gsapDestroy = {
  data() {
    return {
      commonGsapArray: []
    }
  },
  beforeDestroy() {
    if (this.commonGsapArray.length !== 0) {
      for (let item of this.commonGsapArray) {
        item.kill()
      }
    }
  }
}
