<template>
  <div class="common-panel description">
    <div class="common-section description-content">
      <div class="common-transition-title-box single" ref="titleBox">
        <h2 class="common-title-circle lt description-title" ref="title">
          ECOSYSTEM
        </h2>
      </div>
      <div class="common-section-item common-text description-text" ref="text">
        Discover the hottest projects and partners on Function X
      </div>
    </div>
    <div class="description-list">
      <a
        class="description-list-item"
        v-for="item in list"
        :key="item.name"
        :href="item.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="description-list-head">
          <div class="description-list-logo"><i :class="item.logo"></i></div>
          <div class="description-list-type">{{ item.type }}</div>
        </div>
        <h3 class="description-list-title">{{ item.name }}</h3>
        <!--        <div class="description-list-note common-text">{{ item.note }}</div>-->
      </a>
    </div>
    <div class="description-btn">
      <CButton type="comic" :paddingLR="'32px'" :height="42" :minWidth="'initial'" @click="goOut">
        View All
      </CButton>
    </div>
  </div>
</template>
<script>
import { gsapDestroy } from '@/tools/mixin'
import { animeTitleShow } from '@/tools/utils'
import CButton from '@/components/common/CButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'Description',
  components: { CButton },
  data() {
    return {
      itemName: ['Pundi X Chain', 'Ultra', 'Infineon', 'Paxos', 'Tron', 'Maker DAO (DAI)']
      // list: [
      //   {
      //     logo: 'common-icon-description-pundixchain',
      //     type: 'Blockchains',
      //     name: 'Pundi X Chain',
      //     url: 'https://preview.pundix.com/',
      //     note: 'Lorem ipsum dolor sit amet. Est aperiam ducimus non delectus culpa aut fuga enim.'
      //   },
      //   {
      //     logo: 'common-icon-description-ultra',
      //     type: 'Lifestyle',
      //     name: 'Ultra',
      //     url: 'https://ultrataiwan.com/zh-tw/',
      //     note: 'Aut consequatur perferendis et labore quasi qui reiciendis repudiandae.'
      //   },
      //   {
      //     logo: 'common-icon-description-infineon',
      //     type: 'Institution',
      //     name: 'Infineon',
      //     url: 'https://www.infineon.com/',
      //     note: 'Ut veritatis natus quo voluptatum repellendus et vero maiores.'
      //   },
      //   {
      //     logo: 'common-icon-description-paxos',
      //     type: 'DeFi',
      //     name: 'Paxos',
      //     url: 'https://paxos.com/',
      //     note:
      //       'Eum vitae molestias ea laboriosam aliquam et praesentium eligendi vel voluptate repellat sed rerum rerum.'
      //   },
      //   {
      //     logo: 'common-icon-description-tron',
      //     type: 'Blockchains',
      //     name: 'Tron',
      //     url: 'https://trondao.org/',
      //     note: 'Non facilis earum aut sequi dolorem ut cupiditate quia sit aperiam amet.'
      //   },
      //   {
      //     logo: 'common-icon-description-makerdao',
      //     type: 'Stablecoins',
      //     name: 'MakerDAO',
      //     url: 'https://makerdao.com/en/',
      //     note:
      //       'Aut exercitationem illo ut quisquam internos ut quia fugiat. Qui beatae eveniet ut facilis nisi aut autem.'
      //   }
      // ]
    }
  },
  computed: {
    ...mapState(['ecosystemData']),
    list() {
      return Object.keys(this.ecosystemData)
        .reduce((acc, type) => {
          const itemsWithTypes = this.ecosystemData[type].map(item => ({
            ...item,
            type: type, // 添加类型字段
            logo: 'common-icon-description-' + item.name.toLowerCase().replace(/[\s()]/g, '')
          }))
          return acc.concat(itemsWithTypes)
        }, [])
        .filter(item => {
          return this.itemName.includes(item.name)
        })
        .sort((a, b) => {
          return this.itemName.indexOf(a.name) - this.itemName.indexOf(b.name)
        })
    }
  },
  mixins: [gsapDestroy],
  methods: {
    go(link) {
      window.open(link)
    },
    goOut() {
      this.$router.push('/ecosystem')
    }
  },
  mounted() {
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: this.$refs.titleBox,
        start: 'bottom+=50 bottom',
        end: 'bottom+=50 bottom'
      },
      move: 'left'
    })
  }
}
</script>

<style scoped lang="scss">
.description {
  width: 100%;
  padding-bottom: 140px;
}
.description-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.description-title {
  margin-bottom: 30px;
}
.description-text {
  max-width: 720px;
  text-align: center;
}
.description-list {
  display: grid;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 96px;
}
.description-list-item {
  min-height: 184px;
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s;
  padding: 24px;
  &:hover {
    box-shadow: 0 12px 24px 0 rgba(8, 10, 50, 0.06);
    color: initial;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    border: solid 1px rgba(5, 19, 46, 0.08);
    transform-origin: top left;
    transform: scale(0.5);
    border-radius: 40px;
  }
}
.description-list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description-list-logo {
  margin-top: -12px;
  margin-left: -12px;
  width: 96px;
}
.description-list-type {
  border-radius: 13px;
  background-color: #fbfbfb;
  padding: 5px 12px;
  font-size: 14px;
  color: var(--font-sub);
}
.description-list-title {
  margin-top: 8px;
}
.description-list-note {
  margin-top: 8px;
}
.description-btn {
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
