<template>
  <div class="head" :class="{ night: bridgeScrollEnd && isDark }">
    <div class="nav" :class="{ show: bridgeResourceReady, delay: navTransitionDelay }" ref="nav">
      <div class="nav-night-mask" :style="{ opacity: bridgeMask === 0.96 ? 0 : bridgeMask }" v-if="isDark"></div>
      <div class="nav-content common-content">
        <a href="/" class="nav-logo">
          <div class="nav-logo-icon">
            <i class="common-icon-logo-dark"></i>
          </div>
          <div class="nav-logo-text">Function X</div>
        </a>
        <div class="nav-btn">
          <div class="nav-btn-item">
            <router-link to="/home" class="nav-btn-item-link">Home</router-link>
          </div>
          <!--          <div class="nav-btn-item">-->
          <!--            <router-link to="/derivatives" class="nav-btn-item-link">Derivatives</router-link>-->
          <!--          </div>-->
          <!--          <div class="nav-btn-item">-->
          <!--            <router-link to="/fxwallet" class="nav-btn-item-link">f(x)Wallet</router-link>-->
          <!--          </div>-->
          <div class="nav-btn-item">
            <router-link to="/ecosystem" class="nav-btn-item-link">Ecosystem</router-link>
          </div>
          <div class="nav-btn-item" v-for="item in outLink" :key="item.label">
            <a :href="item.link" class="nav-btn-item-link" target="_blank" rel="noopener noreferrer"
              >{{ item.label }}<span class="nav-link-icon"><i class="common-icon-jump-c"></i></span
            ></a>
          </div>
          <div class="nav-btn-item custom-dropdown">
            <i-dropdown
              :placement="'top-start'"
              class="nav-btn-item-drop"
              :transfer-class-name="bridgeScrollEnd && isDark ? 'dark' : ''"
              :transfer="true"
            >
              <!--                            trigger="click"-->
              <div class="nav-btn-item-link more-link" :class="{ active: isJoinUs || active.moreLink }">Community</div>
              <i-dropdown-menu
                slot="list"
                @mouseenter.native="active.moreLink = true"
                @mouseleave.native="active.moreLink = false"
              >
                <i-dropdown-item
                  v-for="item of moreLinkList"
                  :key="item.name"
                  :class="{
                    'level-zero': item.level === 0,
                    'level-first': item.level === 1,
                    context: item.link === ''
                  }"
                >
                  <div class="nav-btn-item-sub" v-if="item.link === ''">
                    <div class="nav-btn-item-sub-title">{{ item.name }}</div>
                  </div>
                  <router-link :to="item.link" class="nav-btn-item-sub" v-else-if="item.link[0] === '/'">
                    <div class="nav-btn-item-icon" v-if="item.icon !== 'jumpArrow'">
                      <div class="nav-btn-item-icon-back">
                        <i :class="`common-icon-${item.icon}`"></i>
                      </div>
                    </div>
                    <div class="nav-btn-item-text" :class="{ 'sub-title': item.icon === 'jumpArrow' }">
                      {{ item.name }}
                    </div>
                    <span class="nav-link-icon" v-if="item.icon === 'jumpArrow'"
                      ><i class="common-icon-jump-r-c"></i
                    ></span>
                  </router-link>
                  <a v-else :href="item.link" class="nav-btn-item-sub" target="_blank" rel="noopener noreferrer"
                    ><div class="nav-btn-item-icon" v-if="item.icon !== 'jumpArrow'">
                      <div class="nav-btn-item-icon-back">
                        <i :class="`common-icon-${item.icon}`"></i>
                      </div>
                    </div>
                    <div class="nav-btn-item-text" :class="{ 'sub-title': item.icon === 'jumpArrow' }">
                      {{ item.name }}
                    </div>
                    <span class="nav-link-icon" v-if="item.icon === 'jumpArrow'"
                      ><i class="common-icon-jump-r-c"></i></span
                  ></a>
                </i-dropdown-item>
              </i-dropdown-menu>
            </i-dropdown>
          </div>
          <div class="nav-btn-item">
            <a
              href="https://support.functionx.io/hc/en-us"
              class="nav-btn-item-link"
              target="_blank"
              rel="noopener noreferrer"
              >FAQ</a
            >
          </div>
          <div class="nav-btn-item download-fx-wallet nav-btn-cbtn">
            <CButton
              class="order-but"
              @click="goToDownload()"
              :type="bridgeScrollEnd && isDark ? 'lineWhite' : 'line'"
              :height="42"
              min-width="164px"
              paddingLR="0"
              ><div class="nav-text-downloadW">
                <div class="nav-icon-downloadW">
                  <i class="common-icon-downloadW"></i>
                </div>
                <div class="download-Wallet-text">Get f(x)Wallet</div>
              </div></CButton
            >
          </div>
          <!--          <div class="nav-btn-item nav-btn-cbtn">-->
          <!--            <CButton class="order-but" @click="goOut()" type="colorful" :height="42" min-width="170px" paddingLR="0"-->
          <!--              ><div class="nav-text-downloadW">-->
          <!--                <div class="nav-icon-downloadW">-->
          <!--                  <i class="common-icon-stock"></i>-->
          <!--                </div>-->
          <!--                <div class="download-Wallet-text">MarginX Trade</div>-->
          <!--              </div></CButton-->
          <!--            >-->
          <!--          </div>-->
          <div class="nav-btn-item nav-btn-cbtn custom-dropdown">
            <i-dropdown
              :placement="'top-start'"
              class="nav-btn-item-drop"
              :transfer-class-name="bridgeScrollEnd && isDark ? 'dark' : ''"
              :transfer="true"
            >
              <!--              trigger="click"-->
              <div class="nav-btn-item-title" :class="{ active: active.community }">
                <CButton
                  class="order-but"
                  type="colorful"
                  :noHoverActive="true"
                  :height="42"
                  :clickRipple="false"
                  min-width="164px"
                  paddingLR="0"
                  ><div class="nav-btn-dapp">
                    <div class="nav-btn-dapp-icon">
                      <i class="common-icon-block"></i>
                    </div>
                    <div class="nav-btn-dapp-text">Dapps</div>
                    <div class="nav-btn-dapp-arrow"><i class="common-icon-arrow-down"></i></div></div
                ></CButton>
              </div>
              <i-dropdown-menu
                slot="list"
                @mouseenter.native="active.community = true"
                @mouseleave.native="active.community = false"
              >
                <i-dropdown-item v-for="(item, key) of DappsList" :key="key">
                  <a :href="item.link" class="nav-btn-item-sub" target="_blank" rel="noopener noreferrer">
                    <div class="nav-btn-item-icon">
                      <div class="nav-btn-item-icon-back">
                        <div class="nav-btn-item-icon-back-px" v-if="item.icon === 'px-card'">
                          <i class="common-icon-px-card-bg"></i>
                          <i class="common-icon-px-card"></i>
                        </div>
                        <i :class="`common-icon-${item.icon}`" v-else></i>
                      </div>
                    </div>
                    <div class="nav-btn-item-text">{{ item.name }}</div>
                  </a>
                </i-dropdown-item>
              </i-dropdown-menu>
            </i-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CButton from '@/components/common/CButton'
import { EventBus } from '@/tools/bus'

export default {
  name: 'Head',
  data() {
    return {
      active: {
        community: false,
        moreLink: false
      },
      show: false,
      isDark: false,
      outLink: [
        { label: 'Bridge', link: 'https://starscan.io/fxbridge' },
        { label: 'StarScan', link: 'https://starscan.io/' }
      ],
      derivativeList: [{ icon: 'fxcore', name: 'Variable', link: '/derivatives/variable' }],
      moreLinkList: [
        {
          level: 0,
          icon: 'telegram',
          name: 'Telegram',
          link: 'https://t.me/functionx'
        },
        {
          level: 0,
          icon: 'forum',
          name: 'StarScan Forum',
          link: 'https://forum.starscan.io/'
        }
        // { level: 0, icon: '', name: 'Developer', link: '' },
        // {
        //   level: 1,
        //   icon: 'monday',
        //   name: 'Product Pipeline',
        //   link: 'https://view.monday.com/2417712327-86b14f3d4fc23cb0e32010beceab8834?r=use1'
        // },
        // {
        //   level: 1,
        //   icon: 'monday',
        //   name: 'Product Release',
        //   link: 'https://view.monday.com/2471253229-cdc7b39b4774964e3c81e414c7326713?r=use1'
        // },
        // { level: 1, icon: 'docBl', name: 'Technical Doc', link: 'https://functionx.gitbook.io/home/' },
        // {
        //   level: 1,
        //   icon: 'EGFBl',
        //   name: 'EGF Guide',
        //   link: 'https://functionx.gitbook.io/functionx-egf-proposal-guidelines/'
        // },
        // { level: 0, icon: 'jumpArrow', name: 'Join Us', link: '/joinus' },
        // { level: 0, icon: 'jumpArrow', name: 'FAQ', link: 'https://support.functionx.io/hc/en-us' }
      ],
      DappsList: [
        { icon: 'marginx', name: 'MarginX', link: 'https://swap.marginx.io/#/swap' },
        // { icon: 'fx-line', name: 'Launch FXSwap', link: 'https://fx-swap.io/' },
        { icon: 'px-card', name: 'p(x)Card', link: 'https://pxcard.io/' }
      ]
    }
  },
  components: {
    CButton
  },
  computed: {
    ...mapState(['COMMUNITY', 'bridgeResourceReady', 'bridgeScrollEnd', 'bridgeMask', 'navTransitionDelay']),
    isJoinUs() {
      return this.$route.path.startsWith('/joinus')
    }
  },
  methods: {
    goToDownload() {
      // if (this.$route.name === 'Home') {
      //   const rect = document.querySelector('#fxWallet').getBoundingClientRect()
      //   window.scrollBy({
      //     top: rect.y,
      //     behavior: 'smooth'
      //   })
      // } else
      if (this.$route.name === 'Wallet') {
        EventBus.$emit('fx-wallet-hover')
      } else {
        this.$router.push('/fxwallet')
      }
    },
    goOut() {
      window.open('https://trade.marginx.io/')
    }
  },
  created() {
    this.$route.matched.forEach(item => {
      if (item.meta.headDark !== undefined) {
        this.isDark = true
      }
    })
  }
}
</script>

<style lang="scss">
.dark {
  .nav-btn-item-icon-back > i,
  .nav-btn-item-sub .nav-btn-item-icon .nav-btn-item-icon-back .nav-btn-item-icon-back-px > i:nth-of-type(1),
  &.ivu-select-dropdown .nav-btn-item-sub span.nav-link-icon > i {
    background-color: rgba(255, 255, 255, 1);
  }
  .nav-btn-item-sub div.nav-btn-item-text,
  .nav-btn-item-sub .nav-btn-item-sub-title {
    color: rgba(255, 255, 255, 1);
    transition: color 0.3s;
  }

  .level-first {
    div.nav-btn-item-text {
      color: rgba(255, 255, 255, 1);
    }
    .nav-btn-item-icon-back > i {
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &.ivu-select-dropdown .nav-btn-item-sub:hover,
  &.ivu-select-dropdown .nav-btn-item-sub.active {
    .nav-btn-item-text {
      color: #5b8ff9;
    }
    .nav-btn-item-icon .nav-btn-item-icon-back > i,
    span.nav-link-icon > i {
      background-color: #5b8ff9;
    }
    .nav-btn-item-icon .nav-btn-item-icon-back .nav-btn-item-icon-back-px > i:nth-of-type(1) {
      background-color: #5b8ff9;
    }
    .nav-btn-item-icon .nav-btn-item-icon-back .nav-btn-item-icon-back-px > i:nth-of-type(2) {
      background-color: var(--main);
    }
  }
}
</style>

<style scoped lang="scss">
@import '~@/assets/style/font.scss';
@import '~@/assets/style/variable.scss';
@import '~@/assets/style/mixin.scss';
.head {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: $headHeight;
  min-width: var(--content-width-padding);
  .nav {
    width: 100%;
    height: $headHeight;
    transform: translateY(-100%);
    transition: transform 0.3s;
    &:before {
      content: '';
      @include position-all();
      transition-duration: 0.3s;
      transition-property: background-color, border-color;
      background-color: rgba(255, 255, 255, 0.98);
      border-bottom: 1px solid rgba(8, 10, 50, 0.08);
    }

    &.show {
      transform: translateY(0);
    }
    &.delay {
      transition-delay: 0.7s;
    }

    .nav-night-mask {
      @include position-all();
      background-color: #fff;
    }

    .nav-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      position: relative;
      z-index: 10;
    }

    .nav-logo {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .nav-logo-icon {
      width: 32px;
      box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
      border-radius: 6px;
    }

    .nav-logo-text {
      margin-left: 8px;
      font-size: 18px;
      color: var(--main);
    }

    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .nav-btn-item {
      height: 100%;
      font-size: 14px;
      @include font-select('cmr_medium');
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .nav-btn-item-link {
        color: var(--font-sub);
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        &:hover:not(.disabled),
        &.active:hover:not(.disabled) {
          color: var(--font-link);
          .nav-link-icon {
            transform: translateX(4px);
            & > i {
              background-color: var(--font-link);
            }
          }
        }
        &.active {
          color: var(--main);
          .nav-link-icon > i {
            background-color: var(--main);
          }
        }
      }
      .nav-btn-item-title {
        color: var(--font-sub);
        transition: color 0.3s;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: var(--font-link);
        }
        &.active {
          color: var(--main);
        }
      }
    }

    .more-link {
      //font-size: 24px;
      //letter-spacing: 1px;
      //margin-top: -3px;
      transition: color 0.3s;
    }

    .download-fx-wallet .nav-icon-downloadW > i {
      background-color: var(--main);
      transition: background-color 0.3s;
    }

    .nav-btn-cbtn {
      .nav-text-downloadW {
        display: flex;
        justify-content: center;
        align-items: center;
        .nav-icon-downloadW {
          width: 24px;
          margin-right: 8px;
        }
        .download-Wallet-text {
          margin-right: 4px;
          font-size: 16px;
        }
      }
      &.nav-btn-item + .nav-btn-cbtn.nav-btn-item {
        margin-left: 16px;
      }
      .nav-btn-dapp {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 142px;
      }
      .nav-btn-dapp-icon {
        width: 24px;
      }
      .nav-btn-dapp-text {
        margin-left: 8px;
        font-size: 16px;
      }
      .nav-btn-dapp-arrow {
        width: 24px;
        margin-left: auto;
        transition: transform 0.3s;
      }
    }

    .nav-btn-item + .nav-btn-item {
      margin-left: 40px;
    }
    .nav-btn-item-drop {
      height: 100%;
      line-height: 60px;
    }
  }
  &.night {
    $bgColor: #1b1a28;
    $normalColor: rgba(255, 255, 255, 0.6);
    $hoverColor: #5b8ff9;
    $activeColor: #fff;
    background-color: $bgColor;
    .nav {
      &:before {
        background-color: $bgColor;
        border-color: $bgColor;
      }
      .nav-logo-text {
        color: $activeColor;
      }
      .nav-btn-item-link {
        color: $normalColor;
        .nav-link-icon > i {
          background-color: $normalColor;
        }
        &:hover:not(.disabled),
        &.active:hover:not(.disabled) {
          color: $hoverColor;
          .nav-link-icon > i {
            background-color: $hoverColor;
          }
        }
        &.active {
          color: $activeColor;
          .nav-link-icon > i {
            background-color: $activeColor;
          }
        }
      }
      .nav-btn-item-title {
        color: $normalColor;
        &:hover {
          color: $hoverColor;
        }
        &.active {
          color: $activeColor;
        }
      }
      .download-fx-wallet .nav-icon-downloadW > i {
        background-color: #fff;
      }
    }
  }

  .c-button:hover .nav-btn-dapp-arrow,
  .nav-btn-item-title.active .nav-btn-dapp-arrow {
    transform: rotate(180deg);
  }
}

.nav-link-icon {
  display: inline-block;
  width: 16px;
  margin-left: 2px;
  transition: transform 0.3s;
  & > i {
    background-color: var(--font-sub);
    transition: background-color 0.3s;
  }
}

.nav-btn-item-sub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 164px;
  min-height: 48px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;

  .nav-btn-item-icon {
    width: 32px;
    position: relative;
    margin-right: 4px;

    .nav-btn-item-icon-back {
      width: 100%;
      & > i {
        transition: background-color 0.3s;
      }
    }
    .nav-btn-item-icon-back-px {
      width: 100%;
      position: relative;
      & > i {
        transition: background-color 0.3s;
        &:nth-of-type(1) {
          background-color: transparent;
        }
        &:nth-of-type(2) {
          background-color: var(--main);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
  .nav-link-icon > i {
    background-color: var(--main);
  }

  &:hover,
  &.active {
    .nav-btn-item-text {
      color: var(--font-link);
    }
    .nav-btn-item-icon-back > i,
    .nav-btn-item-icon-back-px i:nth-of-type(2) {
      background-color: var(--font-link);
    }
    .nav-btn-item-text + .nav-link-icon {
      transform: translateX(4px);
      & > i {
        background-color: var(--font-link);
      }
    }
  }

  .nav-btn-item-link {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.nav-btn-item-text.sub-title {
  color: var(--font-sub);
  & + .nav-link-icon > i {
    background-color: var(--font-sub);
  }
}

.nav-btn-item-sub-title {
  @include font-select('cmr_medium');
  color: var(--font-sub);
}

.level-first + .level-zero {
  margin-top: 12px;
}
.level-first > a {
  padding-left: 20px;
}
</style>
