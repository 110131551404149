<template>
  <div class="market" ref="market">
    <div class="mk-bg-text font-black" :class="{ show: bridgeMask === 0.96 }">
      <div class="mk-bg-text-item">
        <div class="mk-bg-text-item-half" v-for="item in 4" :key="item">
          start_time = time.time() checkpoints = sorted(Path(ckpt_dir).glob("*.pth")) assert len(checkpoints) > 0, f"no
          checkpoint files found in {ckpt_dir}" assert model_parallel_size == len( checkpoints ), f"Loading a checkpoint
          for MP={len(checkpoints)} but world size is {model_parallel_size}" ckpt_path =
          checkpoints[get_model_parallel_rank()] checkpoint = torch.load(ckpt_path, map_location="cpu") with
          open(Path(ckpt_dir) / "params.json", "r") as f: params = json.loads(f.read()) model_args: ModelArgs =
          ModelArgs( max_seq_len=max_seq_len, max_batch_size=max_batch_size, **params, ) tokenizer =
          Tokenizer(model_path=tokenizer_path) model_args.vocab_size = tokenizer.n_words
          torch.set_default_tensor_type(torch.cuda.HalfTensor) model = Transformer(model_args)
          model.load_state_dict(checkpoint, strict=False) print(f"Loaded in {time.time() - start_time:.2f} seconds")
          class Llama: @staticmethod def build( ckpt_dir: str, tokenizer_path: str, max_seq_len: int, max_batch_size:
          int, model_parallel_size: Optional[int] = None, seed: int = 1, ) -> "Llama":
        </div>
      </div>
      <div class="mk-bg-text-item">
        <div class="mk-bg-text-item-half" v-for="item in 4" :key="item">
          if not torch.distributed.is_initialized(): torch.distributed.init_process_group("nccl") if not
          model_parallel_is_initialized(): if model_parallel_size is None: model_parallel_size =
          int(os.environ.get("WORLD_SIZE", 1)) initialize_model_parallel(model_parallel_size) local_rank =
          int(os.environ.get("LOCAL_RANK", 0)) torch.cuda.set_device(local_rank) # seed must be the same in all
          processes torch.manual_seed(seed) if local_rank > 0: sys.stdout = open(os.devnull, "w") start_time =
          time.time() checkpoints = sorted(Path(ckpt_dir).glob("*.pth")) assert len(checkpoints) > 0, f"no checkpoint
          files found in {ckpt_dir}" assert model_parallel_size == len( checkpoints ), f"Loading a checkpoint for
          MP={len(checkpoints)} but world size is {model_parallel_size}" ckpt_path = prompt_tokens: List[List[int]],
          max_gen_len: int, temperature: float = 0.6, top_p: float = 0.9, logprobs: bool = False, echo: bool = False,
        </div>
      </div>
    </div>
    <div class="mk-bg">
      <!--      <div class="mk-sky"></div>-->
      <!--      <div class="mk-cloud" :style="{ transform: `translate3D(0, ${cloudMove}px, 0)` }"></div>-->

      <!--      <div class="mk-hill" :style="{ transform: `translate3D(0, ${hillMove}px, 0)` }"></div>-->
      <div class="mk-machine">
        <div class="mk-machine-big">
          <img src="@/assets/images/derivatives/market/der_01_robot_body.gif" alt="" />
          <div class="mk-machine-big-head">
            <img src="@/assets/images/derivatives/market/der_01_robot_head.png" alt="" />

            <div class="mk-machine-big-light">
              <img src="@/assets/images/derivatives/market/der_01_scan.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="mk-machine-small">
          <img src="@/assets/images/derivatives/market/der_01_UAV.png" alt="" />
        </div>
      </div>
    </div>
    <div class="mk-content">
      <div class="common-transition-box">
        <h2 class="mk-title">
          ONCHAIN AI SUPPORTING MERCHANTS AND ADOPTION
        </h2>
      </div>
      <div class="mk-info common-text">
        Projects and merchants will be able to use large scale AI modeling to build digital products, create NFT loyalty
        items, and elevate user experience - coding tools and no-code one-click options available.
      </div>
    </div>
    <div class="mk-eg" :style="{ transform: `translate3D(0,-${egMove}px,0)` }">
      <!--      <div class="mk-ed-mask"></div>-->
      <!--      <div class="mk-ed-mask"></div>-->
    </div>
  </div>
</template>

<script>
// import { gsap } from '@/tools/gsap'
import { gsapDestroy } from '@/tools/mixin'
import { mapState } from 'vuex'

export default {
  name: 'Market',
  data() {
    return {
      show: false,
      egMove: 0,
      hillMove: 0,
      cloudMove: 0
    }
  },
  mixins: [gsapDestroy],
  computed: {
    ...mapState(['bridgeMask'])
  },
  mounted() {
    // this.commonGsapArray[0] = gsap.to(this.$data, {
    //   duration: 1,
    //   egMove: 200,
    //   hillMove: 150,
    //   cloudMove: 50,
    //   scrollTrigger: {
    //     trigger: this.$refs.market,
    //     start: 'top top+=60px',
    //     end: 'bottom top+=60px',
    //     scrub: true
    //     // markers: process.env.NODE_ENV === 'development'
    //   }
    // })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';
$egPaddingBottom: 200px;
$egHeight: 381px;
.market {
  //margin-top: 180px;
  width: 100%;
  min-height: $marketMinHeight;
  position: relative;
  padding-bottom: $marketPaddingBottom;
  overflow: hidden;
  //background-image: linear-gradient(180deg, #eddcff 1%, #d2ddff 48%, #d2ddff 68%, #ffffff 87%);
  .mk-bg-text {
    @include position-all();
    top: -26px;
    opacity: 0;
    transition: opacity 0.3s;
    background-image: linear-gradient(180deg, #ffffff 1%, #cad6ff 63%);
    overflow: hidden;
    &.show {
      opacity: 1;
    }
  }
  .mk-bg-text-item {
    position: absolute;
    width: 200%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &:nth-of-type(1) {
      left: 0;
      animation: 40s run-back linear infinite;
    }
    &:nth-of-type(2) {
      right: 0;
      animation: 40s run linear infinite;
      top: 48px;
    }
  }
  .mk-bg-text-item-half {
    width: 50%;
    font-size: 26px;
    color: #fff;
    -webkit-text-stroke: 0.5px rgba(8, 10, 50, 0.15);
    //letter-spacing: 3px;
    -webkit-background-clip: text;
    background-clip: text;
    line-height: 4;
    -webkit-text-fill-color: transparent;
    word-spacing: 28px;
    word-break: break-all;
  }
  .mk-bg {
    @include position-all();
    top: 180px;
    bottom: $marketPaddingBottom;
    overflow: hidden;
  }
  //.mk-sky {
  //  @include position-all();
  //  bottom: 0;
  //  background-image: url('~@/assets/images/derivatives/market/der_01_bg.svg');
  //  background-size: auto 902px;
  //  background-position: center top;
  //  background-repeat: no-repeat;
  //}
  .mk-cloud {
    position: absolute;
    width: 100%;
    height: 421px;
    top: 117px;
    will-change: transform;
    background-image: url('~@/assets/images/derivatives/market/der_01_bg_cloud.svg');
    background-size: auto 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .mk-hill {
    position: absolute;
    width: 100%;
    height: 426px;
    bottom: 0;
    will-change: transform;
    background-image: url('~@/assets/images/derivatives/market/der_01_bg_mountain.svg');
    background-size: auto 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
  }
  .mk-machine {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1440px;
  }
  .mk-machine-small {
    position: absolute;
    left: 278px;
    top: 412px;
    width: 126px;
    height: 220px;
    animation: 5s machine-move ease-in-out infinite alternate-reverse;
  }
  .mk-machine-big {
    position: absolute;
    top: 266px;
    right: 385px;
    width: 378px;
  }
  .mk-machine-big-head {
    position: absolute;
    width: 153px;
    top: -180px;
    left: 38px;
    transform-origin: 100px 200px;
    animation: 5s head-rotate linear infinite;
  }
  .mk-machine-big-light {
    position: absolute;
    width: 1137px;
    top: 101px;
    right: 102px;
  }
  .mk-content {
    position: relative;
    z-index: 5;
    width: 900px;
    //padding-top: 88px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: blur(50px);
      background-image: linear-gradient(to bottom, #fff 20%, #eff2ff);
    }
  }
  .mk-title {
    position: relative;
    width: 630px;
  }
  .mk-info {
    position: relative;
    width: 720px;
  }
  .mk-eg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: $egHeight + $egPaddingBottom;
    will-change: transform;
    background-image: url('~@/assets/images/derivatives/market/der_01_plant.gif'), linear-gradient(#fff 0%, #fff 100%);
    background-size: auto $egHeight, 1827px $egPaddingBottom;
    background-position: center top, calc(50% + 8px) bottom;
    background-repeat: no-repeat;
    margin-bottom: -$egPaddingBottom;
  }
  //.mk-ed-mask {
  //  position: absolute;
  //  bottom: 86px + $egPaddingBottom - 32px;
  //  width: 459px;
  //  height: 934px;
  //  background-image: url('~@/assets/images/derivatives/market/der_01_mask.svg');
  //  background-size: 100%;
  //  background-position: left top;
  //  background-repeat: no-repeat;
  //  &:nth-of-type(1) {
  //    left: 50%;
  //    transform: translateX(-1280px);
  //  }
  //  &:nth-of-type(2) {
  //    right: 50%;
  //    transform: scaleX(-1) translateX(-1280px);
  //  }
  //}
}

@keyframes machine-move {
  100% {
    transform: translateY(20px);
  }
}
@keyframes head-rotate {
  0%,
  60%,
  100% {
    transform: rotate(0);
  }
  20%,
  40% {
    transform: rotate(5deg);
  }
}

@keyframes run {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(50%);
  }
}
@keyframes run-back {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-height: 700px) {
  .market {
    .mk-content {
      padding-top: 24px;
    }
    .mk-title {
      font-size: 86px;
      line-height: 72px;
      width: 700px;
    }
  }
}
</style>
