<template>
  <div class="Bridge" :class="{ night: isNight }" ref="bridge" id="bridge">
    <div class="be-sub">
      <!--      <div class="be-context-cd" v-if="countdown">-->
      <!--        <div class="be-context-cd-content">-->
      <!--          <countdown :end-time="String(endTime)" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="be-context-next" :style="{ opacity: nextOpacity }">
        <Case />
        <!--        <div class="be-context-next-item" v-for="(item, key) of info" :key="key">-->
        <!--          <div class="be-context-next-content">-->
        <!--            <h2 class="common-title-circle lt">-->
        <!--              {{ item.title }}-->
        <!--            </h2>-->
        <!--          </div>-->
        <!--          <div class="common-text be-context-next-text">-->
        <!--            {{ item.text }}-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="be-context-market">
        <Market />
        <div class="be-context-next-empty"></div>
      </div>
    </div>
    <div class="be-content">
      <div class="be-bg">
        <div class="be-bg-mountain" v-show="imgReady"></div>
        <div class="be-bg-backlight" v-if="isNight" :class="{ show: imgReady }"></div>
        <div class="be-bg-sky" v-if="isNight">
          <div class="be-bg-sky-star star1"></div>
          <div class="be-bg-sky-star star2"></div>
          <div class="be-bg-sky-star star3"></div>
          <div class="be-bg-sky-star star4"></div>
        </div>
        <div class="be-bg-plane-1"></div>
        <div class="be-bg-plane-2"></div>
        <div class="be-bg-lamp-box" v-if="isNight" :class="{ show: imgReady }">
          <div class="be-bg-lamp" :style="{ transform: `rotate(${lampRotate}deg)` }"></div>
        </div>
        <div class="be-bg-firework" v-if="isNight" :class="{ show: imgReady }">
          <div class="be-bg-firework-line line1"></div>
          <div class="be-bg-firework-line line2"></div>
          <div class="be-bg-firework-line line3"></div>
          <div class="be-bg-firework-line line4"></div>
        </div>
        <div class="be-bg-city" v-show="imgReady">
          <div class="be-bg-city-night" v-if="isNight">
            <div class="be-bg-city-night-item be-bg-city-build"></div>
            <div class="be-bg-city-night-item be-bg-city-window"></div>
            <div class="be-bg-city-night-item be-bg-city-light"></div>
            <div class="be-bg-city-night-item be-bg-city-flash"></div>
          </div>
        </div>
        <div class="be-bg-light" :class="{ show: imgReady }">
          <div class="be-bg-pundi-light be-bg-pundi-light-1"></div>
          <div class="be-bg-pundi-light be-bg-pundi-light-2"></div>
          <div class="be-bg-fx-light be-bg-fx-light-1"></div>
          <div class="be-bg-fx-light be-bg-fx-light-2"></div>
        </div>
        <div class="be-bg-brand-box" :class="{ show: imgReady }">
          <div class="be-bg-brand">
            <div class="be-bg-brand-text font-semi" v-if="isNight">
              CRYPTOBNK
            </div>
          </div>
        </div>
        <div class="be-bg-sprite" v-if="isNight">
          <div
            class="be-bg-sprite-item"
            v-for="(item, key) of sprite"
            :key="key"
            :style="{
              width: item.w + 'px',
              height: item.w + 'px',
              top: item.top + 'px',
              left: item.left + 'px'
            }"
            :class="['sprite-' + item.type]"
          ></div>
        </div>
        <div class="be-bg-mask"></div>
        <!--        <div class="be-bg-moon-box" v-if="isNight">-->
        <!--          <div class="be-bg-moon-halo"></div>-->
        <!--          <div class="be-bg-moon-halo"></div>-->
        <!--          <div class="be-bg-moon be-bg-moon-land"></div>-->
        <!--          <div class="be-bg-moon be-bg-moon-shadow"></div>-->
        <!--          <div class="be-bg-moon be-bg-moon-crater"></div>-->
        <!--        </div>-->
        <!--        <div class="be-bg-moon-mask" v-if="isNight"></div>-->
      </div>
      <div class="be-mask mask1" :style="{ opacity: maskOpacity1 }"></div>
      <div class="be-mask mask2" :style="{ opacity: maskOpacity2 }"></div>
      <div class="be-context">
        <div class="be-context-padding"></div>
        <div class="be-context-start" :class="{ show: showTitle }">
          <div class="be-context-box">
            <transition name="transition-title">
              <h1 class="be-title" v-show="nextText === 0">
                The leading decentralized payment blockchain
                <!--                <div class="be-title-evm">{{ title.title }}</div>-->
                <!--                <div class="icon-x"><i class="common-icon-x"></i></div>-->
                <!--                <div class="be-title-fx">{{ title.title2 }}</div>-->
              </h1>
            </transition>
          </div>
          <transition name="transition-text">
            <div class="be-info-box" v-show="nextText === 0">
              <div class="be-info common-text">
                {{ title.text }}
              </div>
              <!--              <div class="be-map">-->
              <!--                <div class="be-map-dotted"></div>-->
              <!--                <div class="be-map-point">-->
              <!--                  <div class="be-map-point-icon"><i class="common-icon-location"></i></div>-->
              <!--                  EVM-->
              <!--                </div>-->
              <!--                <div class="be-map-solid"></div>-->
              <!--                <div class="be-map-point">f(x)Wallet V.4.0</div>-->
              <!--                <div class="be-map-solid"></div>-->
              <!--                <div class="be-map-point">Margin X</div>-->
              <!--                <div class="be-map-solid"></div>-->
              <!--                <div class="be-map-point">DApps</div>-->
              <!--                <div class="be-map-dotted"></div>-->
              <!--              </div>-->
              <div class="be-links">
                <div class="be-link" v-for="(item, key) in outBtn" :key="key">
                  <CButton
                    type="comicWhite"
                    :hoverForever="key === 0"
                    :paddingLR="'24px'"
                    :height="42"
                    :minWidth="'initial'"
                    @click="goOut(item.link)"
                  >
                    <div class="be-link-content">
                      {{ item.text }}
                      <div class="be-link-icon">
                        <i class="common-icon-jump"></i>
                      </div>
                    </div>
                  </CButton>
                </div>
              </div>
            </div>
          </transition>
          <!--          <transition name="transition-text">-->
          <!--            <div class="be-btn" v-show="nextText === 0">-->
          <!--              <CButton :type="'comic'" :height="42" :min-width="'initial'">-->
          <!--                <div class="common-btn-line">-->
          <!--                  Learn More-->
          <!--                  <div class="common-btn-line-external">-->
          <!--                    <i class="common-icon-external-link"></i>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </CButton>-->
          <!--            </div>-->
          <!--          </transition>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, ScrollTrigger } from '@/tools/gsap'
import { resourceReady } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'
// import CDotButton from '@/components/common/CDotButton'
import CButton from '@/components/common/CButton'
import Case from '@/components/home/Case.vue'
import Market from '@/components/derivatives/Market.vue'
// import Countdown from '@/components/bridge/Countdown'

// import { getNightState } from '@/tools/utils'
// const isNight = getNightState()
const isNight = true
export default {
  name: 'Bridge',
  data() {
    return {
      maskOpacity1: 0,
      nextOpacity: 0,
      infoActive: -1,
      listHeight: 0,
      showTitle: true,
      nextText: -1,
      imgReady: false,
      timer: null,
      timerStop: false,
      isNight: isNight,
      lampRotate: 18,
      sprite: [],
      title: {
        title: 'EVM',
        title2: 'Function X',
        text:
          'Powering the largest network of crypto exchange stores and retail outlets — enabling transactions in Bitcoin, crypto, forex, RWA and NFTs in real life'
      },
      info: [
        {
          title: 'BRIDGING TRADITIONAL & DECENTRALISED MARKET',
          text:
            'We are mirroring traditional financial products on the Function X network and creating decentralized financial products and a decentralized trading system.'
        },
        {
          title: 'Participation from the community',
          text:
            'f(x) community shall be involved in the decision making process to determine the listing of new assets and trading tools. Core team members and developers around the world will jointly develop and upkeep the network.'
        }
      ],
      outBtn: [
        { text: 'Learn More', link: 'https://medium.com/@functionx_io/8fc1959c4017' },
        {
          text: 'f(x)Core Validators',
          link: 'https://functionx.gitbook.io/home/validators/validator-overview'
        },
        {
          text: 'Developer Resources',
          link: 'https://functionx.gitbook.io/home/deploying-on-f-x-core-evm/metamask/add-f-x-core-network'
        },
        { text: 'Apply for EGF', link: 'https://functionx.gitbook.io/functionx-egf-proposal-guidelines/' }
      ]
    }
  },
  components: {
    Market,
    Case,
    // Countdown
    CButton
    // CDotButton
  },
  computed: {
    maskOpacity2: {
      set(val) {
        this.$store.commit('setBridgeMask', val)
      },
      get() {
        return this.$store.state.bridgeMask
      }
    }
  },
  methods: {
    goOut(link) {
      window.open(link)
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    this.commonGsapArray[0] = ScrollTrigger.create({
      trigger: this.$refs.bridge,
      start: 'top+=500 top+=60',
      end: 'top+=500 top+=60',
      toggleActions: 'restart none reverse none',
      markers: process.env.NODE_ENV === 'development',
      onEnter: () => {
        this.maskOpacity1 = 1
      },
      onEnterBack: () => {
        this.maskOpacity1 = 0
      }
    })
    this.commonGsapArray[1] = ScrollTrigger.create({
      trigger: '.be-sub',
      start: 'top-=300 top+=60',
      end: 'top-=300 top+=60',
      toggleActions: 'restart none reverse none',
      markers: process.env.NODE_ENV === 'development',
      onEnter: () => {
        this.$store.commit('setBridgeScrollEnd', false)
      },
      onEnterBack: () => {
        this.$store.commit('setBridgeScrollEnd', true)
      }
    })
    this.commonGsapArray[2] = gsap.to(this, {
      maskOpacity2: 0.96,
      duration: 1,
      scrollTrigger: {
        trigger: this.$refs.bridge,
        start: 'top top+=60px',
        end: 'top+=500 top+=60px',
        scrub: true
        // markers: process.env.NODE_ENV === 'development'
      }
    })
    // gsap.to(this.$data, {
    //   maskOpacity2: 0.96,
    //   duration: 1,
    //   scrollTrigger: {
    //     trigger: this.$refs.bridge,
    //     start: 'top top+=60px',
    //     end: 'bottom bottom',
    //     scrub: true
    //     // markers: process.env.NODE_ENV === 'development'
    //   }
    // })
    document.fonts.ready.then(() => {
      // 字体加载完成后的逻辑
      this.nextText = 0
      this.$store.commit('setBridgeResourceReady', true)
    })

    resourceReady(this.$el).then(() => {
      // 字体和图片加载完成后的逻辑
      this.imgReady = true
    })
    this.commonGsapArray[3] = ScrollTrigger.create({
      trigger: this.$refs.bridge,
      start: 'top+=50px top',
      end: 'top+=50px top',
      toggleActions: 'restart none reverse none',
      // markers: process.env.NODE_ENV === 'development',
      onEnter: () => {
        this.showTitle = false
      },
      onEnterBack: () => {
        this.showTitle = true
      }
    })

    this.commonGsapArray[4] = gsap.to(this.$data, {
      duration: 1,
      nextOpacity: 1,
      scrollTrigger: {
        trigger: this.$refs.bridge,
        start: 'top top',
        end: 'top+=500 top',
        scrub: true
        // markers: process.env.NODE_ENV === 'development'
      }
    })
    // 18 40 -20

    const lampTl = gsap.timeline({ repeat: -1, repeatDelay: 2 })
    lampTl.to(this.$data, { lampRotate: 40, duration: 1 }, '+=2')
    lampTl.to(this.$data, { lampRotate: -20, duration: 3 }, '+=2')
    lampTl.to(this.$data, { lampRotate: 18, duration: 2 }, '+=2')
    lampTl.to(this.$data, { lampRotate: 40, duration: 1 }, '+=1')
    lampTl.to(this.$data, { lampRotate: 18, duration: 1 }, '+=1')

    const spriteNum = 20
    const spritePosition = [1200, 130]
    const spriteSize = [4, 14]
    const spriteArray = []
    for (let i = 0; i < spriteNum; i++) {
      const sprite = {
        top: (Math.floor(Math.random() * 100) / 100) * spritePosition[1],
        left: (Math.floor(Math.random() * 100) / 100) * spritePosition[0],
        w: (Math.floor(Math.random() * 10) / 10) * spriteSize[1] + spriteSize[0],
        type: i % 4
      }
      spriteArray.push(sprite)
    }
    this.sprite = spriteArray
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';
@import '~@/assets/style/mixin.scss';
@import '~@/assets/style/font.scss';
$listTop: 100px;
$maxMarginTop: 56;
$starRun: 2;
$starDisappear: 0.5;
$starDelay: 6;
$starWidth: 81px;
$starHeight: 76px;
$starLine: 500px;
$fireworkRun: 1.5;
$fireworkDisappear: 0.5;
$fireworkDelay: 1;
$smallScreenCityHeight: 290px;
$smallScreenCityBottom: -24px;
$smallScreenCityLeft: 270px;
@mixin fireworkLine($name, $delay) {
  animation: #{$fireworkRun + $fireworkDelay + 's'} firework-translate-#{$name} $delay infinite;
  &:before {
    animation: #{$fireworkRun + $fireworkDelay + 's'} firework-scale $delay infinite;
  }
}

@mixin fireworkAnime($name, $y) {
  @keyframes firework-translate-#{$name} {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    #{($fireworkRun - $fireworkDisappear) / ($fireworkRun + $fireworkDelay) * 100 + '%'} {
      opacity: 1;
    }
    #{$fireworkRun / ($fireworkRun + $fireworkDelay) * 100 + '%'} {
      opacity: 0;
      transform: translateY($y);
    }
    100% {
      opacity: 0;
      transform: translateY($y);
    }
  }
}

#bridge {
  .show {
    opacity: 1;
  }
}

.Bridge {
  width: 100%;
  height: calc(2500px - #{$contextHeight} - #{$ecCardTop} - #{$headHeight} - #{$headHeight} + #{$titleChangeHeight});
  position: relative;
  //.be-context-cd {
  //  position: sticky;
  //  top: $headHeight;
  //  margin-bottom: $contextHeight - $countdownHeight - ($countdownMargin * 2);
  //  width: 100%;
  //  padding-top: $countdownMargin;
  //  padding-bottom: $countdownMargin;
  //  background-color: #fff;
  //  background-image: linear-gradient(
  //    rgba(220, 5, 5, 0.05) 0%,
  //    rgba(220, 5, 5, 0.05) 100%
  //  );
  //  z-index: 10;
  //  .be-context-cd-content {
  //    width: var(--content-width);
  //    margin-left: auto;
  //    margin-right: auto;
  //    padding-left: 32px;
  //  }
  //}
  //&.countdown {
  //  .be-sub {
  //    top: 0;
  //  }
  //  .be-content {
  //    padding-top: $countdownHeight + ($countdownMargin * 2);
  //    .be-bg {
  //      .be-bg-mask {
  //        top: $countdownHeight + ($countdownMargin * 2);
  //      }
  //    }
  //  }
  //}
  .be-sub {
    position: absolute;
    top: $contextHeight;
    left: 0;
    right: 0;
    //bottom: $contextHeight - $headHeight;
    bottom: 0;
    //background-color: #ccc;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    .be-context-next {
      opacity: 0;
      position: sticky;
      flex-shrink: 0;
      flex-grow: 0;
      //background-color: #999;
      top: $headHeight;
      //width: var(--content-width);
      min-height: $contextHeight;
      //padding: 65px 32px 0 32px;
      //padding-top: 65px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $marketHeight + $marketPaddingTop;
      //display: flex;
      //justify-content: space-between;
      //align-items: flex-start;
    }
    .be-context-market {
      flex-shrink: 0;
      flex-grow: 0;
      position: sticky;
      padding-top: $marketPaddingTop;
      top: $caseHeight + $headHeight + $marketPaddingTop;
      margin-top: -$marketHeight - $marketPaddingTop;
      width: 100%;
    }
    .be-context-next-empty {
      height: $marketEmpty;
      background-color: #fff;
    }
    .be-context-next-item {
      width: 464px;
    }
    .be-context-next-content {
      padding-top: 40px;
      overflow: hidden;
    }
    .be-context-next-text {
      width: 390px;
      margin-top: 8px;
    }
  }

  .be-content {
    position: sticky;
    top: $headHeight;
    height: calc(100vh - #{$headHeight});
    //background-image: linear-gradient(to bottom, blue 0%, red 100%);
    //background-image: url('~@/assets/images/home/bridgeImg.png'),
    //  url('~@/assets/images/home/bridgeBG.jpg');
    //background-size: 100% auto, cover;
    //background-repeat: no-repeat;
    //background-position: center 500px, center;
    .be-bg {
      @include position-all;
      z-index: -1;
      overflow: hidden;
      .be-bg-sky {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $contextHeight;
      }
      .be-bg-sky-star {
        width: $starWidth;
        height: $starHeight;
        background-image: url('~@/assets/images/home/bridge/first-screen_star.svg');
        background-size: 100%;
        position: absolute;
        bottom: 100%;

        &.star1 {
          right: 0;
          animation: #{$starRun + $starDelay + 's'} shooting-star 2s ease-in infinite;
        }
        &.star2 {
          right: 30%;
          animation: #{$starRun + $starDelay + 's'} shooting-star 7s ease-in infinite;
        }
        &.star3 {
          right: 50%;
          animation: #{$starRun + $starDelay + 's'} shooting-star 5s ease-in infinite;
        }
        &.star4 {
          right: 70%;
          animation: #{$starRun + $starDelay + 's'} shooting-star 7s ease-in infinite;
        }
      }
      .be-bg-lamp-box {
        position: absolute;
        width: 45px;
        height: 660px;
        bottom: 94px;
        left: 50%;
        opacity: 0;
        transform: translateX(235px);
      }
      .be-bg-lamp {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/home/bridge/first-screen_spotlight.svg');
        background-size: 100%;
        transform-origin: bottom center;
      }
      .be-bg-firework {
        width: 52px;
        height: 220px;
        position: absolute;
        left: 50%;
        bottom: 222px;
        transform: translateX(-258px);
        opacity: 0;
      }
      .be-bg-firework-line {
        width: 2px;
        height: 109px;
        position: absolute;
        top: 100%;
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(to bottom, rgba(149, 249, 255, 1) 0%, rgba(149, 249, 255, 0) 100%);
          transform-origin: top center;
        }
        &.line1 {
          left: 10px;
        }
        &.line2 {
          left: 26px;
        }
        &.line3 {
          left: 31px;
        }
        &.line4 {
          left: 39px;
        }
      }
      .be-bg-firework.show {
        .line1 {
          @include fireworkLine('1', 1s);
        }
        .line2 {
          @include fireworkLine('2', 0.5s);
        }
        .line3 {
          @include fireworkLine('3', 1.5s);
        }
        .line4 {
          @include fireworkLine('4', 0s);
        }
      }
      .be-bg-brand-box {
        width: 45px;
        height: 176px;
        position: absolute;
        bottom: 226px;
        left: 50%;
        transform: translateX(152px);
        opacity: 0;
      }
      .be-bg-brand {
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/images/home/bridge/first-screen_crypto.png');
        background-size: 100%;
        animation: 1s band-move linear infinite alternate;
      }
      .be-bg-brand-text {
        font-size: 30px;
        min-width: 124px;
        transform-origin: left top;
        transform: translate(43px, 14px) rotate(90deg);
        font-weight: 600;
        letter-spacing: 0;
        background-image: linear-gradient(to right, #a49fff 0%, #ff9bee 33%, #cb7efe 66%, #a49fff 100%);
        animation: 2s bg-move linear infinite;
        background-size: 100%;
        background-position: 0 100px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .be-bg-sprite {
        position: absolute;
        width: 1200px;
        height: 130px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 90px;
      }
      .be-bg-sprite-item {
        position: absolute;
        border-radius: 100px;
        background-color: #fff;
        opacity: 0;
      }
      .sprite-0 {
        animation: 2s sprite-move 1s linear infinite alternate;
      }
      .sprite-1 {
        animation: 2s sprite-move 0.5s linear infinite alternate;
      }
      .sprite-2 {
        animation: 2s sprite-move 1.4s linear infinite alternate;
      }
      .sprite-3 {
        animation: 2s sprite-move 0.2s linear infinite alternate;
      }
      .be-bg-mountain {
        @include position-all;
        background-image: url('~@/assets/images/home/bridge/first-screen_bg_5K.jpg');
        background-size: 2560px auto;
        background-position: center bottom;
        background-repeat: no-repeat;
      }
      .be-bg-backlight {
        @include position-all;
        bottom: -280px;
        background-image: url('~@/assets/images/home/bridge/first-screen_bglight.svg');
        background-size: 1440px auto;
        background-position: center bottom;
        background-repeat: no-repeat;
      }
      .be-bg-city {
        @include position-all;
        background-image: url('~@/assets/images/home/bridge/first-screen_img_5k.gif');
        background-size: 2560px auto;
        background-position: center bottom;
        background-repeat: no-repeat;
      }
      .be-bg-plane-1 {
        position: absolute;
        bottom: 537px;
        left: 50%;
        width: 145px;
        height: 8px;
        background-image: url('~@/assets/images/home/bridge/first-screen_plane_left.png');
        background-size: 100% auto;
        animation: 15s move-plane-2 linear infinite;
      }
      .be-bg-plane-2 {
        position: absolute;
        bottom: 470px;
        right: 50%;
        width: 85px;
        height: 15px;
        background-image: url('~@/assets/images/home/bridge/first-screen_plane_right.png');
        background-size: 100% auto;
        animation: 30s move-plane-1 linear infinite;
      }
      .be-bg-light {
        position: absolute;
        @include position-all();
        opacity: 0;
        transition: opacity 0.3s 1s;
      }
      .be-bg-pundi-light {
        position: absolute;
        bottom: 358px;
        left: 50%;
        width: 64px;
        height: 38px;
        transform: translateX(-166px);
        background-size: 100% auto;
      }
      .be-bg-pundi-light-1 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_pundi_01.png');
        animation: 0.3s shine infinite steps(1, end);
      }
      .be-bg-pundi-light-2 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_pundi_02.png');
        animation: 0.3s shine 0.15s infinite steps(1, end);
      }
      .be-bg-fx-light {
        position: absolute;
        bottom: 158px;
        left: 50%;
        width: 93px;
        height: 47px;
        transform: translateX(111px);
        background-size: 100%;
      }
      .be-bg-fx-light-1 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_fx_01.png');
        animation: 0.3s shine infinite steps(1, end);
      }
      .be-bg-fx-light-2 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_fx_02.png');
        animation: 0.3s shine 0.15s infinite steps(1, end);
      }
      .be-bg-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 500px;
        background-image: linear-gradient(180deg, #ffffff 66%, rgba(255, 255, 255, 0) 100%);
      }
      .be-bg-moon-box {
        position: absolute;
        top: -140px;
        left: 50%;
        transform: translateX(360px) scale(3);
        width: 160px;
        height: 160px;
      }
      .be-bg-moon-halo {
        border-radius: 400px;
        &:nth-of-type(1) {
          @include position-all(-10px);
          background-color: #fff;
          animation: 9s moon-halo-size infinite;
        }
        &:nth-of-type(2) {
          @include position-all(1px);
          filter: blur(2px);
          opacity: 0.5;
          background-color: #bff2ff;
          animation: 1.12s moon-halo-color infinite;
        }
      }
      .be-bg-moon-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background-image: linear-gradient(179deg, rgba(27, 26, 40, 0.99) 0%, rgba(27, 26, 40, 0) 100%);
      }
      .be-bg-moon-land {
        background-image: url('~@/assets/images/home/bridge/first-screen_moon_01.png');
        animation: 180s moon-rotate infinite linear;
      }
      .be-bg-moon-shadow {
        background-image: url('~@/assets/images/home/bridge/first-screen_moon_02.png');
      }
      .be-bg-moon-crater {
        background-image: url('~@/assets/images/home/bridge/first-screen_moon_03.png');
        animation: 180s moon-rotate infinite linear;
      }
      .be-bg-moon {
        @include position-all();
        background-size: 100%;
        z-index: 10;
      }
    }
    .be-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      opacity: 0;
      z-index: -1;
      &.mask1 {
        transition: opacity 0.3s;
      }
    }

    .be-context {
      min-height: $contextHeight;
      width: var(--content-width);
      margin-left: auto;
      margin-right: auto;
      padding-left: 32px;
      padding-top: 32px;
      position: relative;
      .be-context-padding {
        width: 100%;
        max-height: #{$maxMarginTop}px;
        // (100vh - 900px) / (1440px - 900px) * 56px
        height: calc((100vh - 900px) / 540 * #{$maxMarginTop});
      }
      .be-context-start {
        width: 900px;
        opacity: 0;
        transition: opacity 0.3s;
        &.show {
          opacity: 1;
        }
        .be-title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-transform: uppercase;
          //margin-left: -10px;
        }
        .be-info {
          font-size: 16px !important;
          margin-top: 8px;
        }
        .be-btn {
          margin-top: 24px;
        }
      }
      .be-context-box {
        position: relative;
        overflow: hidden;
      }
    }
    .icon-x {
      flex-shrink: 0;
      width: 44px;
      margin-left: 50px;
      margin-right: 50px;
    }
    .be-title-evm {
      font-size: 110px;
      @include font-select('sfmono');
    }
    .be-title-fx {
      margin-bottom: -16px;
    }

    .be-map {
      margin-top: 32px;
      padding: 25px;
      background-image: url('~@/assets/images/home/bridge/barbg.png');
      background-size: auto 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .be-map-dotted {
      width: 22px;
      height: 0;
      border-top: 1px dashed #fff;
    }
    .be-map-point {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include font-select('cmr_medium');
      margin-left: 10px;
      margin-right: 10px;
      font-size: 16px;
      color: #ffffff;
      line-height: 1em;
      font-weight: 500;
    }
    .be-map-point-icon {
      width: 16px;
      margin-right: 4px;
    }
    .be-map-solid {
      width: 40px;
      height: 1px;
      background-color: #fff;
    }

    .be-links {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 32px;
    }
    .be-link + .be-link {
      margin-left: 16px;
    }
    .be-link-content {
      white-space: nowrap;
      font-size: 18px;
      color: #ffffff;
      line-height: 18px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .be-link-icon {
      margin-left: 4px;
      width: 16px;
      transition: transform 0.3s;
    }
    .c-button:hover .be-link-icon {
      transform: translateX(4px);
    }
  }

  &.night {
    .be-bg {
      background-color: #1b1a28;
      .be-bg-city {
        background-image: none;
      }
      .be-bg-city-night {
        @include position-all();
      }
      .be-bg-city-night-item {
        @include position-all();
        background-size: 2560px auto;
        background-position: center bottom;
        background-repeat: no-repeat;
      }
      .be-bg-city-build {
        background-image: url('~@/assets/images/home/bridge/first-screen_img.gif');
      }
      .be-bg-city-window {
        background-image: url('~@/assets/images/home/bridge/first-screen_img_01.png');
      }
      .be-bg-city-light {
        background-image: url('~@/assets/images/home/bridge/first-screen_img_02.png');
      }
      .be-bg-city-flash {
        background-image: url('~@/assets/images/home/bridge/first-screen_img_03.png');
      }
      .be-bg-mountain {
        background-image: url('~@/assets/images/home/bridge/first-screen_bg_2k.jpg');
      }
      .be-bg-plane-1 {
        background-image: url('~@/assets/images/home/bridge/first-screen_plane_left_night.png');
      }
      .be-bg-plane-2 {
        background-image: url('~@/assets/images/home/bridge/first-screen_plane_right_night.png');
      }
      .be-bg-pundi-light-1 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_pundi_01_night.png');
      }
      .be-bg-pundi-light-2 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_pundi_02_night.png');
      }
      .be-bg-fx-light-1 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_fx_01_night.png');
      }
      .be-bg-fx-light-2 {
        background-image: url('~@/assets/images/home/bridge/first-screen_light_fx_02_night.png');
      }
      .be-bg-brand {
        background-image: url('~@/assets/images/home/bridge/first-screen_crypto_night.png');
      }
    }
    .be-context {
      color: #fff;
      .be-info {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .be-content {
      .be-bg {
        .be-bg-mask {
          background-image: linear-gradient(180deg, rgba(27, 26, 40, 1) 44%, rgba(27, 26, 40, 0) 100%);
        }
      }
    }
  }

  .move-v {
    transition: transform 0.3s;
  }
  @keyframes shine {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes move-plane-1 {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      transform: translateX(-1000px);
      opacity: 0;
    }
  }
  @keyframes move-plane-2 {
    0% {
      transform: translateX(-1000px);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    95% {
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }
  @keyframes shooting-star {
    0% {
      opacity: 1;
      transform: translate(0, 0);
    }
    #{($starRun - $starDisappear) / ($starRun + $starDelay) * 100 + '%'} {
      opacity: 1;
    }
    #{$starRun / ($starRun + $starDelay) * 100 + '%'} {
      opacity: 0;
      transform: translate(-#{$starLine}, #{$starLine * $starHeight / $starWidth});
    }
    100% {
      opacity: 0;
      transform: translate(-#{$starLine}, #{$starLine * $starHeight / $starWidth});
    }
  }
  @keyframes firework-scale {
    0% {
      transform: scaleY(1);
    }
    #{($fireworkRun - $fireworkDisappear) / ($fireworkRun + $fireworkDelay) * 100 + '%'} {
      transform: scaleY(1);
    }
    #{$fireworkRun / ($fireworkRun + $fireworkDelay) * 100 + '%'} {
      transform: scaleY(0.4);
    }
    100% {
      transform: scaleY(0.4);
    }
  }
  @include fireworkAnime('1', -230px);
  @include fireworkAnime('2', -210px);
  @include fireworkAnime('3', -200px);
  @include fireworkAnime('4', -220px);

  @keyframes bg-move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -124px 0;
    }
  }
  @keyframes band-move {
    100% {
      transform: translateY(4px);
    }
  }
  @keyframes sprite-move {
    from {
      opacity: 0;
      transform: translateY(0);
    }
    to {
      transform: translateY(6px);
      opacity: 1;
    }
  }
  @keyframes moon-halo-color {
    0% {
      background-color: #bff2ff;
    }
    #{ 160 / 1120 * 100 + '%'} {
      background-color: #bff2ff;
    }
    #{(160 + 40) / 1120 * 100 + '%'} {
      background-color: #ffbffe;
    }
    #{(160 + 40 + 200) / 1120 * 100 + '%'} {
      background-color: #ffbffe;
    }
    #{(160 + 40 + 200 + 40) / 1120 * 100 + '%'} {
      background-color: #c4a1ff;
    }
    #{(160 + 40 + 200 + 40 + 640) / 1120 * 100 + '%'} {
      background-color: #c4a1ff;
    }
    100% {
      background-color: #bff2ff;
    }
  }
  @keyframes moon-halo-size {
    0% {
      transform: scale(1);
      opacity: 0.08;
    }
    #{3000 / 9000 * 100 + '%'} {
      transform: scale(1.2);
      opacity: 0;
    }
    #{(3000 + 2000) / 9000 * 100 + '%'} {
      transform: scale(1);
      opacity: 0.08;
    }
    #{(3000 + 2000 + 2000) / 9000 * 100 + '%'} {
      transform: scale(1.2);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0.08;
    }
  }
  @keyframes moon-rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
@media screen and (max-height: 700px) {
  #bridge {
    .be-bg-mountain {
      top: initial;
      height: 540px;
      background-size: cover;
      background-position: center bottom;
      //background-repeat: initial;
    }
    .be-bg-backlight {
      top: initial;
      bottom: $smallScreenCityBottom;
      height: $smallScreenCityHeight;
      background-position: calc(50% - #{$smallScreenCityLeft}) bottom;
      background-size: auto $smallScreenCityHeight;
    }
    .be-bg-city {
      top: initial;
      bottom: $smallScreenCityBottom;
      height: $smallScreenCityHeight;
      background-position: calc(50% - #{$smallScreenCityLeft}) bottom;
      background-size: auto $smallScreenCityHeight;
      background-repeat: initial;
    }
    .be-bg-city-night-item {
      background-size: auto $smallScreenCityHeight;
      background-position: calc(50% - #{$smallScreenCityLeft}) bottom;
      background-repeat: initial;
    }
    .be-bg-pundi-light {
      bottom: 155px;
      width: 32px;
      height: 19px;
      transform: translateX(-341px);
    }
    .be-bg-fx-light {
      bottom: 55px;
      width: 46px;
      height: 23px;
      transform: translateX(-213px);
    }
    .be-bg-plane-1 {
      bottom: 244px;
      width: 72px;
      height: 4px;
    }
    .be-bg-plane-2 {
      bottom: 211px;
      width: 42px;
      height: 7px;
    }
    .be-bg-mask {
      background-image: linear-gradient(180deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%);
    }
    .be-title {
      font-size: 86px;
      line-height: 72px;
      max-width: 670px;
    }
    .be-title-evm {
      font-size: 80px;
    }
    .be-info {
      margin-top: 10px;
    }
    .be-map,
    .be-links {
      margin-top: 16px;
    }
    .be-context {
      padding-top: 24px;
    }
    .be-context-padding {
      height: 0;
    }
    .be-bg-lamp-box {
      width: 23px;
      height: 330px;
      bottom: 36px;
      left: 50%;
      transform: translateX(-160px);
    }
    .be-bg-firework {
      bottom: 90px;
      transform: translateX(-400px) scale(0.5);
      transform-origin: left bottom;
    }
    .be-bg-brand-box {
      bottom: 44px;
      transform: translateX(-205px) scale(0.5);
    }
    .be-bg-sprite {
      transform: translateX(calc(-50% - 280px)) scale(0.5);
      bottom: 0;
    }
    .be-bg-moon-box {
      transform: translateX(300px) scale(0.5);
      top: -60px;
    }
    .be-bg-moon-mask {
      height: 100px;
    }
    &.night {
      .be-bg-mask {
        background-image: linear-gradient(180deg, rgba(27, 26, 40, 1) 44%, rgba(27, 26, 40, 0) 100%);
      }
      .be-bg-pundi-light {
        transform: translateX(-353px);
      }
      .be-bg-fx-light {
        transform: translateX(-214px);
      }
    }
  }
}
</style>
