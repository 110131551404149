var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Ecosystem"},[_c('div',{staticClass:"ec-container"},[_vm._m(0),_c('div',{staticClass:"ec-content"},[_c('div',{staticClass:"ec-block-box-container"},[_c('div',{staticClass:"ec-block-box",class:{ wc: _vm.wc, show: _vm.show }},[_c('div',{staticClass:"ec-block ec-block-1"}),_c('div',{staticClass:"ec-block ec-block-2"})])]),_c('div',{staticClass:"ec-l"},[_c('div',{staticClass:"ec-l-context"},[_c('div',{ref:"titleBox",staticClass:"common-transition-title-box"},[_c('h3',{ref:"title",staticClass:"common-title-circle lt ec-l-title"},[_vm._v(" #1       "),_c('div',{staticClass:"common-text font-condensed ec-l-title-text"},[_vm._v(" The number 1 decentralized physical payment blockchain network ")])])]),_c('div',{ref:"text"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-back"},[_c('div',{staticClass:"ec-back-item ec-back-item-1"}),_c('div',{staticClass:"ec-back-item ec-back-item-2"}),_c('div',{staticClass:"ec-back-item ec-back-item-3"}),_c('div',{staticClass:"ec-back-item ec-back-item-5"}),_c('div',{staticClass:"ec-back-shadow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-l-text-item"},[_c('h3',{staticClass:"ec-l-title"},[_vm._v("ONCHAIN")]),_c('div',{staticClass:"common-text ec-l-text"},[_vm._v(" Bridging retailers from traditional payment to onchain payment ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-l-text-item"},[_c('h3',{staticClass:"ec-l-title"},[_vm._v("PHYSICAL STORES")]),_c('div',{staticClass:"common-text ec-l-text"},[_vm._v(" Powering the world’s largest physical crypto exchange store ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-l-text-item"},[_c('h3',{staticClass:"ec-l-title"},[_vm._v("PAYMENTS")]),_c('div',{staticClass:"common-text ec-l-text"},[_vm._v(" Pay using crypto ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-l-text-item"},[_c('h3',{staticClass:"ec-l-title"},[_vm._v("PURCHASES")]),_c('div',{staticClass:"common-text ec-l-text"},[_vm._v(" Buy your first NFTs and RWAs in real life ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-l-text-item"},[_c('h3',{staticClass:"ec-l-title"},[_vm._v("EVERYTHING")]),_c('div',{staticClass:"common-text ec-l-text"},[_vm._v(" Onchain, any chain, any wallet ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ec-r"},[_c('div',{staticClass:"ec-r-img ec-r-cloud-1"},[_c('img',{attrs:{"src":require("@/assets/images/home/ecosystem_cloud_left.png"),"alt":""}})]),_c('div',{staticClass:"ec-r-img ec-r-cloud-2"},[_c('img',{attrs:{"src":require("@/assets/images/home/ecosystem_cloud_right.png"),"alt":""}})]),_c('div',{staticClass:"ec-r-img ec-r-platform"},[_c('img',{attrs:{"src":require("@/assets/images/home/ecosystem_img.gif"),"alt":""}})]),_c('div',{staticClass:"ec-r-img ec-r-fx"},[_c('img',{attrs:{"src":require("@/assets/images/home/ecosystem_fx.png"),"alt":""}})]),_c('div',{staticClass:"ec-r-img ec-r-cloud-3"},[_c('img',{attrs:{"src":require("@/assets/images/home/ecosystem_cloud_right.png"),"alt":""}})])])
}]

export { render, staticRenderFns }