<template>
  <div class="Fxcoin common-panel">
    <div class="fc-content">
      <div class="fc-context">
        <div class="common-transition-title-box single" ref="titleBox">
          <h2 class="common-title-circle lt fc-title" ref="title">
            FX Coin
          </h2>
        </div>
        <div class="fc-info" ref="text">
          <div class="common-text fc-text">
            $FX token is the governance token for the Function X project. Users may use $FX to vote, as collateral
            (soon), to generate synthetic assets (soon) delegate, securing the core network and more.
            <br />
            <br />
            $FX token is available in Ethereum since 2019 and in Function X since 2021. They are fully interoperable and
            users can transfer $FX in and out of both blockchains while maintaining a fix total supply and liquidity.
            More chains are scheduled in Q4 2021 and 2022.
          </div>
          <div class="fc-num-btn">
            <CButton type="comic" :height="42" :minWidth="'225px'" @click="goOut">
              <div class="fc-num-btn-content">
                How to delegate
                <div class="fc-num-btn-icon">
                  <i class="common-icon-jumpB"></i>
                </div>
              </div>
            </CButton>
          </div>
        </div>
      </div>
      <div class="fc-num">
        <div class="fc-num-item fc-num-1">
          <div class="fc-num-item-context">
            <div class="fc-num-balance">
              <ICountUp :endVal="locked" :options="lockedOptions" ref="locked" />{{ lockedUnit }}
              FX
            </div>
            <!--            <div class="fc-num-more"></div>-->
            <!--            <div class="fc-num-unit">USD</div>-->
          </div>
          <div class="fc-num-note">
            Delegated into f(x)Core mainnet
            <!--            <div class="pop-text"><i class="common-icon-pop"></i></div>-->
          </div>
        </div>
        <div class="fc-num-item fc-num-2">
          <div class="fc-num-balance"><ICountUp :endVal="staking" :options="stakingOptions" :delay="0.5" />% APR</div>
          <div class="fc-num-note">
            Delegation reward
          </div>
        </div>
      </div>
    </div>
    <div class="fc-progress">
      <div class="fc-progress-bg"></div>
      <div class="fc-progress-line">
        <div class="fc-progress-line-item">
          <div class="fc-progress-line-back">LOCK&gt;</div>
          <div class="fc-progress-line-fx">
            <i class="common-icon-coin-fx"></i>
          </div>
        </div>
        <div class="fc-progress-line-item">
          <div class="fc-progress-line-back">MINT&gt;</div>
          <div class="fc-progress-line-fx">
            <i class="common-icon-coin-fx"></i>
          </div>
        </div>
        <div class="fc-progress-line-item">
          <div class="fc-progress-line-back">&lt;UNLOCK</div>
          <div class="fc-progress-line-fx">
            <i class="common-icon-coin-fx"></i>
          </div>
        </div>
        <div class="fc-progress-line-item">
          <div class="fc-progress-line-back">&lt;BURN</div>
          <div class="fc-progress-line-fx">
            <i class="common-icon-coin-fx"></i>
          </div>
        </div>
      </div>
      <div class="fc-progress-front">
        <div class="fc-progress-box">
          <div class="fc-progress-box-container">
            <div class="fc-progress-box-title">Function X</div>
            <div class="fc-progress-box-icon">
              <i class="common-icon-chain-core"></i>
            </div>
            <div class="fc-progress-box-info">
              <div class="fc-progress-box-info-icon">
                <i class="common-icon-coin-fx"></i>
              </div>
              <div class="fc-progress-box-info-text">Generating Token</div>
            </div>
          </div>
        </div>
        <div class="fc-progress-box">
          <div class="fc-progress-box-container">
            <div class="fc-progress-box-ball"></div>
            <div class="fc-progress-box-ball"></div>
            <div class="fc-progress-box-title">Cross-chain Nodes</div>
            <div class="fc-progress-box-icon">
              <i class="common-icon-chain-node"></i>
            </div>
            <div class="fc-progress-box-info">
              <div class="fc-progress-box-info-text">
                Maintain Consistency of Supply
              </div>
            </div>
          </div>
        </div>
        <div class="fc-progress-box">
          <div class="fc-progress-box-container">
            <div class="fc-progress-box-title">Ethereum</div>
            <div class="fc-progress-box-icon">
              <i class="common-icon-chain-eth"></i>
            </div>
            <div class="fc-progress-box-info">
              <div class="fc-progress-box-info-icon">
                <i class="common-icon-coin-fx"></i>
              </div>
              <div class="fc-progress-box-info-text">
                Financial System Circulation
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="fc-btn">-->
    <!--      <CButton :type="'line'" :height="42" :min-width="'initial'">-->
    <!--        <div class="common-btn-line">-->
    <!--          Get $FX instantly-->
    <!--          <div class="common-btn-line-external">-->
    <!--            <i class="common-icon-external-link"></i>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </CButton>-->
    <!--    </div>-->
  </div>
</template>

<script>
// import CButton from '@/components/common/CButton'
import { animeTitleShow } from '@/tools/utils'
import http from '@/http/api'
// import { getShowNumByNum } from '@/tools/filter'
import BN from '@/tools/BN'
import ICountUp from 'vue-countup-v2'
import CButton from '@/components/common/CButton'
import { gsapDestroy } from '@/tools/mixin'
const zeroBN = BN.clone({ DECIMAL_PLACES: 0, ROUNDING_MODE: 1 })

export default {
  name: 'Fxcoin',
  data() {
    return {
      locked: 0,
      staking: 0,
      lockedUnit: '',
      info: {
        locked: null,
        staking: null
      },
      httpComplete: null,
      lockedOptions: {
        duration: 1.5
        // decimalPlaces: 2
      },
      stakingOptions: {
        duration: 2
      }
    }
  },
  methods: {
    goOut() {
      window.open('https://medium.com/functionx/function-x-june-hash-out-part-2-75e31446d82e')
    }
  },
  components: {
    CButton,
    ICountUp
  },
  computed: {},
  created() {
    this.httpComplete = http
      .coinStakedInfo()
      .then(val => {
        const locked = new zeroBN(val.bondedToken).toString(10)
        // let locked = getShowNumByNum(
        //   utils.formatUnits(val.totalPledge, 'ether')
        // )
        // locked = locked.replace(/,/g, '')
        // const hasUnit = locked.match(/[MB]/)
        // let unit = ''
        // if (hasUnit !== null) {
        //   unit = hasUnit[0]
        //   locked = locked.replace(/[MB]/, '')
        // }
        this.info.locked = Number(locked)
        // this.lockedUnit = unit
        this.info.staking = Number(val.rewardRate * 100)
      })
      .catch(e => {
        console.log('error', e)
      })
  },
  mixins: [gsapDestroy],
  mounted() {
    const scrollTriggerEnter = new Promise(resolve => {
      this.commonGsapArray[0] = animeTitleShow({
        titleEl: this.$refs.title,
        textEl: this.$refs.text,
        scrollTrigger: {
          trigger: this.$refs.titleBox,
          start: 'bottom+=50 bottom',
          end: 'bottom+=50 bottom',
          onEnter() {
            resolve()
          }
        },
        move: 'left'
      })
    })
    Promise.all([this.httpComplete, scrollTriggerEnter]).then(() => {
      this.locked = this.info.locked
      this.staking = this.info.staking
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/font.scss';
@import '~@/assets/style/mixin.scss';
@import '~@/assets/style/variable.scss';

@mixin anime($delay, $move) {
  animation: 5s back-light $delay linear infinite;
  .fc-progress-line-back {
    animation: 5s font-light $delay linear infinite;
  }
  .fc-progress-line-fx {
    animation: 5s $move $delay linear infinite;
  }
}
.Fxcoin {
  width: 100%;
  //padding-bottom: 48px;
  .fc-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .fc-context {
    width: 534px;
  }
  .fc-title {
    padding-bottom: $transitionTitleBottom;
  }
  .fc-text {
    margin-top: 8px;
  }
  .fc-num {
    flex-grow: 1;
    margin-left: 68px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .fc-num-item {
  }
  .fc-num-item-context {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .fc-num-balance {
    @include font-select('teko_medium');
    font-size: 86px;
    line-height: 86px;
  }
  //.fc-num-more {
  //  @include font-select('cmr_medium');
  //  align-self: flex-end;
  //  font-size: 36px;
  //  line-height: 36px;
  //}
  .fc-num-unit {
    margin-top: -10px;
    font-size: 15px;
    line-height: 24px;
    transform-origin: left bottom;
    transform: rotate(90deg);
  }
  .fc-num-note {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .fc-num-btn {
    margin-top: 30px;
  }
  .fc-num-btn-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
  .fc-num-btn-icon {
    margin-left: 4px;
    width: 16px;
    transition: transform 0.3s;
  }
  .c-button:hover .fc-num-btn-icon {
    transform: translateX(4px);
  }
  .pop-text {
    margin-left: 4px;
    width: 20px;
  }
  .fc-num-ch {
    display: inline-block;
    width: 2ch;
    text-align: right;
  }
  .fc-num-1 {
    margin-top: 64px;
  }
  .fc-num-2 {
    min-width: 290px;
    margin-left: auto;
    margin-top: 48px;
  }
  .fc-progress {
    width: 100%;
    padding-top: 83px;
    padding-bottom: 105px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .fc-progress-bg {
      position: absolute;
      top: -200px;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('~@/assets/images/home/fxcoin/bg.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center bottom;
      z-index: -1;
    }
    .fc-progress-line {
      position: absolute;
      left: 238px;
      right: 270px;
      display: grid;
      grid-column-gap: 200px;
      grid-template-rows: 96px 96px;
      grid-template-columns: 1fr 1fr;
      z-index: -1;
      .fc-progress-line-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .fc-progress-line-back {
          @include font-select('teko_medium');
          font-size: 42px;
          line-height: 42px;
          font-weight: 500;
          opacity: 0.2;
        }
        .fc-progress-line-fx {
          width: 72px;
          position: absolute;
          left: -72px;
        }
        &:nth-of-type(1) {
          @include anime(0s, fx-move);
        }
        &:nth-of-type(2) {
          @include anime(3.5s, fx-move);
        }
        &:nth-of-type(3) {
          @include anime(3.5s, fx-move-r);
        }
        &:nth-of-type(4) {
          @include anime(0s, fx-move-r);
        }
      }
    }
    .fc-progress-front {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding-left: 38px;
      padding-right: 70px;
    }
    .fc-progress-box {
      background-color: #ffffff;
      border: 2px solid #080a32;
      border-radius: 4px;
      width: 200px;
      position: relative;
      z-index: 1;
      .fc-progress-box-container {
        overflow: hidden;
        position: relative;
        z-index: 1;
        background-color: #fff;
        min-height: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        @include point-shadow;
        z-index: -1;
      }
      &:nth-of-type(1) {
        &:before {
          top: 8px;
          left: -8px;
          right: 8px;
          bottom: -8px;
        }
        .fc-progress-box-info {
          margin-top: 48px;
        }
        .fc-progress-box-info-icon {
          margin-left: 26px;
          position: relative;
          //background-color: #fff;
          z-index: 1;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -10px;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 4px solid rgba(0, 0, 0, 0.04);
            z-index: -1;
          }
        }
        .fc-progress-box-info-text {
          margin-left: 8px;
          width: 88px;
        }
      }
      &:nth-of-type(2) {
        &:before {
          top: 8px;
          left: -5px;
          right: -5px;
          bottom: -8px;
        }
        .fc-progress-box-ball {
          position: absolute;
          width: 72px;
          height: 72px;
          border-radius: 50%;
          filter: blur(20px);
          z-index: -1;
          &:nth-of-type(1) {
            background-image: linear-gradient(45deg, #1d97c7 0%, #5178f0 53%, #875ff3 100%);
            left: -50px;
            top: 74px;
            animation: 5s ball-light 0s infinite;
          }
          &:nth-of-type(2) {
            background-color: #004167;
            right: -50px;
            top: 170px;
            animation: 5s ball-light 0s infinite;
          }
        }
        .fc-progress-box-info {
          width: 169px;
          margin: 44px auto 29px;
        }
        .fc-progress-box-info-text {
          text-align: center;
        }
      }
      &:nth-of-type(3) {
        &:before {
          top: 8px;
          left: 8px;
          right: -8px;
          bottom: -8px;
        }
        .fc-progress-box-info {
          margin-top: 36px;
        }
        .fc-progress-box-info-icon {
          margin-left: 24px;
        }
        .fc-progress-box-info-text {
          margin-left: 16px;
          width: 121px;
        }
      }
    }
    .fc-progress-box-title {
      margin-top: 24px;
      font-size: 26px;
      line-height: 26px;
      font-weight: 500;
      text-align: center;
      @include font-select('teko_medium');
    }
    .fc-progress-box-icon {
      width: 128px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 18px;
    }
    .fc-progress-box-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .fc-progress-box-info-icon {
      width: 32px;
      flex-shrink: 0;
    }
    .fc-progress-box-info-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .fc-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @keyframes font-light {
    0% {
      opacity: 0.1;
    }
    10% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
  }
  @keyframes ball-light {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    80% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes back-light {
    0% {
      background-color: rgba(255, 255, 255, 0);
      box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0);
    }
    10% {
      background-color: rgba(255, 255, 255, 0.08);
      box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.04);
    }
    70% {
      background-color: rgba(255, 255, 255, 0.08);
      box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.04);
    }
    80% {
      background-color: rgba(255, 255, 255, 0);
      box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(255, 255, 255, 0);
      box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0);
    }
  }
  @keyframes fx-move {
    0% {
      transform: translateX(0) scale(0.55);
    }
    70% {
      transform: translateX(290px) scale(0.88);
    }
    100% {
      transform: translateX(290px) scale(0.88);
    }
  }
  @keyframes fx-move-r {
    0% {
      transform: translateX(290px) scale(0.55);
    }
    70% {
      transform: translateX(0) scale(0.88);
    }
    100% {
      transform: translateX(0) scale(0.88);
    }
  }
}
</style>
