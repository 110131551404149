<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    console.error('not clear console')
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  //height: 100vh;
  //position: relative;
  //overflow-y: auto;
  //overflow-x: hidden;
}
</style>
