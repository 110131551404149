<template>
  <div class="common-panel wallet">
    <div class="common-section wt-context">
      <div class="common-transition-title-box single" ref="titleBox">
        <transition name="transition-title">
          <h2 class="common-title-circle lt wt-title" ref="title">
            F(x)WALLET - The easiest way to access Web3
          </h2>
        </transition>
      </div>
      <transition name="transition-text-b`">
        <div class="common-section-item common-text wt-text" ref="text">
          <div class="wt-feature">
            <!--        <label-->
            <!--          class="wt-feature-item-box"-->
            <!--          v-for="item of feature"-->
            <!--          :key="item.title"-->
            <!--        >-->
            <!--          <input type="radio" class="wt-feature-radio" name="feature" />-->
            <div class="wt-feature-item" v-for="item of feature" :key="item.title">
              <div class="wt-feature-item-icon">
                <i :class="'common-icon-' + item.icon"></i>
              </div>
              <div class="wt-feature-item-text">
                <div class="wt-feature-item-text-title font-bold">
                  {{ item.title }}
                </div>
                <div class="wt-feature-item-text-note">{{ item.note }}</div>
              </div>
            </div>
            <!--        </label>-->
          </div>
          <div class="wt-btn">
            <CButton type="comic" :height="42" @click="goOut">
              <div class="common-btn-content">
                Learn more
              </div>
            </CButton>
          </div>
        </div>
      </transition>

      <!--      <div class="common-section-item">-->
      <!--        <a href="" target="_blank" rel="noopener noreferrer">Learn More →</a>-->
      <!--      </div>-->
    </div>
    <div class="wt-download">
      <div class="wt-download-fx">
        <!--        <i class="common-icon-fx"></i>-->
        <div class="wt-download-fx-plant">
          <img src="~@/assets/images/home/fxwalletRT.jpg" alt="" />
        </div>
        <div class="wt-download-fx-line">
          <img src="~@/assets/images/home/fxwalletRB.jpg" alt="" />
        </div>
      </div>
      <div class="wt-download-store">
        <CPoptip v-for="item of download" :key="item.title" :placement="item.placement">
          <ButtonDownload :link="item.link" :icon="item.icon" :title="item.title" :note="item.note"></ButtonDownload>
          <template v-slot:pop>
            <div class="common-qr-box"><QRCode :text="item.link"></QRCode></div>
            <div class="common-qr-info common-text-qr">Scan to install</div>
          </template>
        </CPoptip>
      </div>
      <div class="app-direct-download-box">
        <CPoptip placement="bottom">
          <TextDownload />
          <template v-slot:pop>
            <div class="common-qr-box">
              <QRCode :text="downloadAPK"></QRCode>
            </div>
            <div class="common-qr-info common-text-qr">Scan to download</div>
          </template>
        </CPoptip>
      </div>
    </div>
  </div>
</template>

<script>
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'
import CButton from '@/components/common/CButton.vue'
import CPoptip from '@/components/common/CPoptip.vue'
import QRCode from '@/components/common/QRCode.vue'
import ButtonDownload from '@/components/common/ButtonDownload.vue'
import TextDownload from '@/components/common/TextDownload.vue'
import { mapState } from 'vuex'

export default {
  name: 'Wallet',
  components: { TextDownload, ButtonDownload, QRCode, CPoptip, CButton },
  data() {
    return {
      feature: [
        {
          icon: 'f1',
          title: 'NON-CUSTODIAL',
          note:
            'Complete control and domination over your private keys and data. Your data and digital assets are only accessible to you.'
        },
        {
          icon: 'f2',
          title: 'EVM COMPATIBILITY',
          note: 'Interact with any EVM-compatible networks and dApps.'
        },
        {
          icon: 'f3',
          title: 'MULTI-CHAIN',
          note:
            'Support and hold multiple public chain assets. Equipped with a multi-address and blockchain management dashboard that enables one to switch addresses or blockchains back and forth seamlessly.'
        }
      ]
    }
  },
  computed: {
    ...mapState(['download', 'downloadAPK'])
  },
  methods: {
    goOut() {
      this.$router.push('/fxwallet')
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: this.$refs.titleBox,
        start: 'bottom+=50 bottom',
        end: 'bottom+=50 bottom'
      },
      move: 'left'
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';

.wallet {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 56px;

  .text-block {
    white-space: nowrap;
  }

  .wt-context {
    width: 636px;
  }
  .wt-context-text {
  }
  .wt-title {
    padding-bottom: $transitionTitleBottom;
  }
  .wt-text {
    color: var(--main);
  }
  .wt-feature {
    margin-left: -10px;
    width: 100%;
    //margin-top: 24px;

    .wt-feature-item-box {
      display: block;

      & + .wt-feature-item-box {
        margin-top: 8px;
      }
    }

    .wt-feature-radio {
      display: none;
    }

    .wt-feature-radio:checked + .wt-feature-item {
      opacity: 1;
      background-color: #fff;
      box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
    }

    .wt-feature-item {
      //min-height: 92px;
      padding: 22px 16px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 12px;
      //opacity: 0.2;
      //transition-duration: 0.3s;
      //transition-property: opacity, background-color, box-shadow;
      //cursor: pointer;
      //&:hover {
      //  opacity: 0.5;
      //  background-color: #fff;
      //  box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
      //}
      & + .wt-feature-item {
        margin-top: 8px;
      }
    }

    .wt-feature-item-icon {
      flex-shrink: 0;
      width: 32px;
    }

    img {
      display: block;
      width: 100%;
    }

    .wt-feature-item-text {
      line-height: 24px;
      margin-left: 12px;
    }

    .wt-feature-item-text-title {
      font-size: 16px;
      font-weight: 500;
    }

    .wt-feature-item-text-note {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .wt-btn {
    margin-top: 32px;
  }

  .wt-download {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 25px;
    margin-top: 68px;
    .app-direct-download-box {
      margin-top: 20px;
      margin-bottom: 16px;
      padding-bottom: 10px;
    }

    .wt-download-fx {
      width: 203px;
      height: 330px;
      .wt-download-fx-plant {
        width: 100%;
        animation: 6s move 0s infinite;
      }
      .wt-download-fx-line {
        width: 22px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
      }
      img {
        display: block;
        width: 100%;
      }
    }

    .wt-download-store {
      margin-top: -44px;
      display: flex;
      justify-content: center;
      align-items: center;
      & > div + div {
        margin-left: 58px;
      }
    }

    .wt-download-android {
      display: block;
      width: 135px;
    }

    .wt-download-ios {
      display: block;
      margin-left: 22px;
      width: 120px;
    }
  }

  @keyframes move {
    0% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
}
</style>
