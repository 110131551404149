<template>
  <div
    @click="handle"
    @mouseenter="enter"
    @mousemove="move"
    class="c-button font-bold"
    :style="{
      '--t': comicShadow + 'px',
      height: height + 'px',
      minWidth: minWidth,
      paddingLeft: paddingLR,
      paddingRight: paddingLR
    }"
    :class="[
      type,
      { disable: disable },
      { loading: loading },
      { hoverForever: hoverForever },
      { active: active },
      { 'no-hover-active': noHoverActive }
    ]"
    @mouseleave="leave"
  >
    <!--      '&#45;&#45;d': transitionDuration + 's'-->
    <div class="c-button-bg"></div>
    <div class="c-button-text">
      <div v-if="loading" class="c-button-loading">
        <i class="common-icon-loading roll"></i>
      </div>
      <template v-else>
        <slot></slot>
      </template>
    </div>
    <div class="c-button-ripple">
      <div
        class="c-button-ripple-item"
        :style="{ left: rippleX + 'px', top: rippleY + 'px' }"
        v-for="item of ripple"
        :key="item"
      ></div>
    </div>
    <div v-if="active" class="c-button-mask" @mouseenter="activeHandle"></div>
  </div>
</template>

<script>
const COMICSHADOWOFFSET = 4
export default {
  name: 'CButton',
  data() {
    return {
      ripple: [],
      rippleX: '',
      rippleY: '',
      btnWidth: 0,
      comicShadow: 0,
      timer: true,
      transitionDuration: 0.3,
      enterTimer: null,
      leaveTimer: null
    }
  },
  props: {
    type: {
      type: String, // 'dark' 'white' 'link'  'comic' 'comicWhite' 'colorful' 'line' 'lineWhite' 'pill'
      default: 'dark'
    },
    disable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    hoverForever: {
      type: Boolean,
      default: false
    },
    noHoverActive: {
      type: Boolean,
      default: false
    },
    clickRipple: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: null
    },
    minWidth: {
      type: String,
      default: '200px'
    },
    paddingLR: {
      type: String,
      default: '32px'
    }
  },
  methods: {
    enter(e) {
      if (this.type === 'comic' || this.type === 'comicWhite') {
        this.btnWidth = this.$el.offsetWidth
      } else if (this.type === 'colorful') {
        if (this.enterTimer !== null) {
          clearTimeout(this.enterTimer)
        }
        this.enterTimer = setTimeout(() => {
          this.transitionDuration = 0
        }, 300)
        this.setRipple(e)
      }
    },
    leave() {
      if (this.type === 'comic' || this.type === 'comicWhite') {
        this.transitionDuration = 0.3
      }
    },
    move(e) {
      if (this.type !== 'comic' && this.type !== 'comicWhite') {
        return
      }
      if (this.timer) {
        this.timer = false
        requestAnimationFrame(() => {
          this.comicShadow =
            Math.floor((COMICSHADOWOFFSET - (e.offsetX / this.btnWidth) * (COMICSHADOWOFFSET * 2)) * 100) / 100

          this.timer = true
        })
      }
    },
    handle(e) {
      if (this.disable || this.loading) {
        return
      }
      this.$emit('click')
      if (this.clickRipple) {
        this.setRipple(e)
      }
    },
    activeHandle(e) {
      this.setRipple(e)
    },
    setRipple(e) {
      const btnInfo = this.$el.getBoundingClientRect()
      this.rippleX = e.clientX - btnInfo.left
      this.rippleY = e.clientY - btnInfo.top
      this.ripple.push(new Date().getTime())
      setTimeout(() => {
        this.ripple.shift()
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';
$radius: 400px;
.c-button {
  min-width: 200px;
  height: 56px;
  box-sizing: border-box;
  border-radius: $radius;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.3s;
  .c-button-bg {
    @include position-all();
  }
  .c-button-text {
    transition-duration: 1s;
    transition-property: color;
  }
  .c-button-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $radius;
  }
  &:not(.disable):not(.loading).dark:active,
  &:not(.disable):not(.loading).white:active {
    transform: scale(0.95);
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: $radius;
    background-image: linear-gradient(45deg, #1d97c7 0%, #5178f0 53%, #875ff3 100%);
    box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0);
    opacity: 0;
    transition-duration: 1s;
    transition-property: opacity, box-shadow;
  }

  &:not(.disable):not(.loading).dark.active:before,
  &:not(.disable):not(.loading).white.active:before {
    box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0);
    opacity: 1;
  }
  &:not(.disable):not(.loading).dark:hover:before,
  &:not(.disable):not(.loading).white:hover:before {
    box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
    opacity: 1;
  }
  &:not(.disable):not(.loading).dark:active:before,
  &:not(.disable):not(.loading).white:active:before {
    box-shadow: 0 6px 10px 0 rgba(8, 10, 50, 0.18);
  }

  &.dark {
    background-color: var(--main);
    .c-button-text {
      color: #fff;
    }
  }
  &.white {
    background-color: #fff;
    .c-button-text {
      color: var(--main);
    }
  }
  &.white:not(.disable):not(.loading):hover,
  &.white:not(.disable):not(.loading).active {
    .c-button-text {
      color: #fff;
    }
  }
  &.link {
    background-color: #fafafa;
    .c-button-text {
      transition-duration: 0.3s;
      color: var(--main);
    }
  }
  &.link:not(.disable):not(.loading):hover,
  &.link:not(.disable):not(.loading).active {
    .c-button-text {
      color: var(--font-link);
    }
  }
  &.line,
  &.lineWhite {
    border: 2px solid #05132e;
    transition-duration: 0.3s;
    transition-property: border-color, transform;
    .c-button-text {
      transition-duration: 0.3s;
      color: var(--main);
    }
    &:not(.disable):not(.loading):before {
      background-image: linear-gradient(45deg, #1d97c7 0%, #5178f0 53%, #875ff3 100%);
    }
    &:not(.disable):not(.loading):hover:before,
    &:not(.disable):not(.loading).active:before {
      opacity: 0.1;
    }
    &:not(.disable):not(.loading):hover {
      transform: translateY(-2px);
    }
  }
  &.lineWhite {
    border: 2px solid #fff;
    .c-button-text {
      color: #fff;
    }
  }
  &.comic {
    border-radius: 4px;
    .c-button-bg {
      z-index: -2;
      background-color: rgba(255, 255, 255, 1);
      transform: translateZ(0);
    }
    &:before {
      border: 2px solid #05132e;
      background-image: none;
      border-radius: 4px;
      opacity: 1;
      z-index: 1;
    }
    &:not(.disable):not(.loading):hover .c-button-bg {
      background-color: rgba(255, 255, 255, 0.96);
      border-radius: 4px;
    }
    &:not(.disable):not(.loading):hover {
      transform: translateY(-2px);
    }
    &:not(.disable):not(.loading):active {
      transform: translateY(0);
    }
    &:after {
      content: '';
      @include position-all();
      @include point-shadow();
      z-index: -3;
      //transition: transform var(--d);
      transition: transform 0.3s;
    }
    &:not(.disable):not(.loading):hover:after {
      transform: translate(var(--t), 4px);
    }
    &:not(.disable):not(.loading):active:after {
      transform: translate(var(--t), 2px);
    }
  }
  &.comicWhite {
    border-radius: 4px;
    color: #fff;
    .c-button-bg {
      z-index: -2;
      background-color: rgba(27, 26, 40, 1);
      transform: translateZ(0);
    }
    &:before {
      border: 2px solid #fff;
      background-image: none;
      border-radius: 4px;
      opacity: 1;
      z-index: 1;
    }
    &:not(.disable):not(.loading):hover .c-button-bg,
    &:not(.disable):not(.loading).hoverForever .c-button-bg {
      background-color: rgba(27, 26, 40, 0.96);
      border-radius: 4px;
    }
    &:not(.disable):not(.loading):hover,
    &:not(.disable):not(.loading).hoverForever {
      transform: translateY(-2px);
    }
    &:not(.disable):not(.loading):active {
      transform: translateY(0);
    }
    &:after {
      content: '';
      @include position-all();
      @include point-shadow('white');
      z-index: -3;
      //transition: transform var(--d);
      transition: transform 0.3s;
    }
    &:not(.disable):not(.loading):hover:after,
    &:not(.disable):not(.loading).hoverForever:after {
      transform: translate(var(--t), 4px);
    }
    &:not(.disable):not(.loading):active:after {
      transform: translate(var(--t), 2px);
    }
  }
  &.colorful {
    color: #fff;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      border: 1px solid #fff;
      transform: scale(0.5);
      transform-origin: left top;
      border-radius: $radius;
    }
    &:not(.disable):not(.loading):before {
      opacity: 1;
    }
    &:not(.disable):not(.loading):not(.no-hover-active):hover {
      transform: translateY(-2px);
    }
    &:not(.disable):not(.loading):not(.no-hover-active):active {
      transform: scale(0.95);
    }
  }
  &.pill {
    background-color: #fff;
    color: var(--font-note);
    box-shadow: 0px 8px 20px 0px transparent;
    transition: box-shadow 0.3s;
    .c-button-text {
      transition-duration: 0.3s;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 200%;
      transform-origin: left top;
      transform: scale(0.5);
      border: 1px solid transparent;
    }
    &:not(.disable):not(.loading):not(.no-hover-active):hover {
      color: var(--font-sub);
      box-shadow: 0px 8px 20px 0px rgba(8, 10, 50, 0.06);
      &:before {
        border-color: rgba(5, 19, 46, 0.08);
      }
    }
    &:not(.disable):not(.loading):not(.no-hover-active):active,
    &:not(.disable):not(.loading):not(.no-hover-active).active {
      color: var(--main);
      box-shadow: 0px 8px 20px 0px rgba(8, 10, 50, 0.06);
      &:before {
        border-color: rgba(5, 19, 46, 0.08);
      }
    }
  }
  &.disable {
    pointer-events: none;
    &.dark {
      opacity: 0.1;
    }
    &.white {
      background-color: rgba(49, 50, 74, 0.5);
      .c-button-text {
        opacity: 0.2;
        color: #fff;
      }
    }
  }
  & .c-button-loading {
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .roll {
    animation: roll 1s infinite linear;
  }
  .c-button-ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: $radius;
    .c-button-ripple-item {
      position: absolute;
      width: 100%;
      padding-top: 100%;
      border-radius: 50%;
      background-color: #f6f8ff;
      transform: translate(-50%, -50%) scale(0.01);
      opacity: 0;
      animation: ripple 0.5s ease-in;
    }
  }
}

@keyframes roll {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ripple {
  25% {
    opacity: 0.07;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
</style>
