<template>
  <a
    class="btn-download"
    :href="link"
    target="_blank"
    rel="noopener noreferrer"
    :class="[shadow, { hover: hover }]"
    :style="{ 'min-width': minWidth }"
  >
    <div class="btn-download-icon">
      <i :class="icon"></i>
    </div>
    <div class="btn-download-context">
      <div class="btn-download-title">{{ title }}</div>
      <div class="btn-download-note font-condensed">
        <div class="btn-download-note-before">
          {{ note }}
        </div>
        <div class="btn-download-note-after">
          Click to install
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ButtonDownload',
  props: {
    link: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    shadow: {
      type: String, // normal 、dark
      default: 'normal'
    },
    minWidth: {
      type: String,
      default: '160px'
    },
    hover: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.btn-download {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  //min-width: 160px;
  border-radius: 28px;
  height: 48px;
  padding-left: 12px;
  box-sizing: border-box;
  position: relative;
  transition-duration: 0.3s;
  transition-property: background-color, box-shadow;
  background-color: rgba(255, 255, 255, 0.5);

  &:before {
    content: '';
    border: 1px solid rgba(5, 19, 46, 0.08);
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    transform: scale(0.5);
    transform-origin: top left;
    border-radius: 56px;
  }

  &:hover,
  &.hover {
    background-color: rgba(255, 255, 255, 1);
  }
  &:hover.normal,
  &.hover.normal {
    box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
  }
  &:hover.dark,
  &.hover.dark {
    box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.1);
  }

  & + .btn-download {
    margin-left: 58px;
  }

  .btn-download-icon {
    width: 24px;
    flex-shrink: 0;
  }

  .btn-download-context {
    margin-left: 8px;
    width: 100%;
  }

  .btn-download-title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: var(--main);
  }

  .btn-download-note {
    margin-top: 1px;
    font-size: 12px;
    color: var(--font-sub);
    line-height: 12px;
    font-weight: 400;
    position: relative;
  }

  .btn-download-note-before {
    opacity: 1;
    transition: opacity 0.3s;
  }
  .btn-download-note-after {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(10px);
    transition: transform 0.3s, opacity 0.3s;
  }

  &:hover,
  &.hover {
    .btn-download-note-before {
      opacity: 0;
    }
    .btn-download-note-after {
      opacity: 1;
      pointer-events: none;
      transform: translateY(0);
    }
  }
}
</style>
