<template>
  <div class="Ecosystem">
    <div class="ec-container">
      <div class="ec-back">
        <div class="ec-back-item ec-back-item-1"></div>
        <div class="ec-back-item ec-back-item-2"></div>
        <div class="ec-back-item ec-back-item-3"></div>
        <div class="ec-back-item ec-back-item-5"></div>
        <div class="ec-back-shadow"></div>
      </div>
      <div class="ec-content">
        <div class="ec-block-box-container">
          <div class="ec-block-box" :class="{ wc: wc, show: show }">
            <div class="ec-block ec-block-1"></div>
            <div class="ec-block ec-block-2"></div>
          </div>
        </div>
        <div class="ec-l">
          <div class="ec-l-context">
            <div class="common-transition-title-box" ref="titleBox">
              <h3 class="common-title-circle lt ec-l-title" ref="title">
                #1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div class="common-text font-condensed ec-l-title-text">
                  The number 1 decentralized physical payment blockchain network
                </div>
              </h3>
            </div>
            <div ref="text">
              <div class="ec-l-text-item">
                <h3 class="ec-l-title">ONCHAIN</h3>
                <div class="common-text ec-l-text">
                  Bridging retailers from traditional payment to onchain payment
                </div>
              </div>
              <div class="ec-l-text-item">
                <h3 class="ec-l-title">PHYSICAL STORES</h3>
                <div class="common-text ec-l-text">
                  Powering the world’s largest physical crypto exchange store
                </div>
              </div>
              <div class="ec-l-text-item">
                <h3 class="ec-l-title">PAYMENTS</h3>
                <div class="common-text ec-l-text">
                  Pay using crypto
                </div>
              </div>
              <div class="ec-l-text-item">
                <h3 class="ec-l-title">PURCHASES</h3>
                <div class="common-text ec-l-text">
                  Buy your first NFTs and RWAs in real life
                </div>
              </div>
              <div class="ec-l-text-item">
                <h3 class="ec-l-title">EVERYTHING</h3>
                <div class="common-text ec-l-text">
                  Onchain, any chain, any wallet
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ec-r">
          <div class="ec-r-img ec-r-cloud-1">
            <img src="@/assets/images/home/ecosystem_cloud_left.png" alt="" />
          </div>
          <div class="ec-r-img ec-r-cloud-2">
            <img src="@/assets/images/home/ecosystem_cloud_right.png" alt="" />
          </div>
          <div class="ec-r-img ec-r-platform">
            <img src="@/assets/images/home/ecosystem_img.gif" alt="" />
          </div>
          <div class="ec-r-img ec-r-fx">
            <img src="@/assets/images/home/ecosystem_fx.png" alt="" />
          </div>
          <div class="ec-r-img ec-r-cloud-3">
            <img src="@/assets/images/home/ecosystem_cloud_right.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import // gsap
'@/tools/gsap'
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'
export default {
  name: 'Ecosystem',
  data() {
    return {
      blockBlurState: false,
      show: false,
      boxX: 0,
      wc: false
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: '.Ecosystem',
        start: 'bottom-=300 bottom',
        end: 'bottom-=300 bottom',
        onEnter: () => {
          this.show = true
        }
      },
      move: 'left',
      option: {
        delay: 0.3
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';
@import '~@/assets/style/mixin.scss';
$ringTop: 50px;
$cardHeight: 656px;
$cardRadius: 36px;
.Ecosystem {
  margin-top: #{$contextHeight + $headHeight - $titleChangeHeight - $ringTop - 1300px};
  width: 100%;
  height: $cardHeight + $ringTop + $titleChangeHeight + 780px;
  .ec-container {
    padding-top: $ringTop;
    width: 100%;
    position: sticky;
    top: calc(100vh - #{$ecCardTop} - #{$ringTop});
    z-index: 1;
    overflow: hidden;
    min-height: $cardHeight;
    .ec-back {
      position: absolute;
      top: 0;
      left: 50%;
      width: var(--content-width);
      bottom: 0;
      z-index: -1;
      //z-index: 10;
      filter: blur(20px);
      transform: translateX(-50%);
      .ec-back-item {
        position: absolute;
        right: 0;
        top: $ringTop;
        border-radius: 1000px;
      }
      .ec-back-item-1 {
        width: 436px;
        height: 436px;
        background-color: #7f62f2;
        animation: 6s move-1 1s linear infinite reverse;
      }
      .ec-back-item-2 {
        width: 270px;
        height: 270px;
        background-color: #fd8bb7;
        animation: 8s move-1 1s linear infinite;
      }
      .ec-back-item-3 {
        width: 270px;
        height: 270px;
        background-color: #5975f1;
        animation: 10s move-1 1s linear infinite reverse;
      }
      .ec-back-item-5 {
        width: 470px;
        height: 470px;
        background-color: #fd8bb7;
        animation: 12s move-1 1s linear infinite;
      }
      .ec-back-shadow {
        @include position-all();
        top: $ringTop;
        border-radius: $cardRadius;
        box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.04);
      }
    }
    .ec-content {
      margin-left: auto;
      margin-right: auto;
      width: var(--content-width);
      height: 100%;
      background-image: url('~@/assets/images/home/ecosystem_bg_1200.jpg'),
        linear-gradient(to bottom, #ebcbed 0%, #9593e1 100%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: $cardRadius;
      overflow: hidden;
      position: relative;
      .ec-block-box-container {
        position: absolute;
        width: 586px;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: $cardRadius 0 0 $cardRadius;
        overflow: hidden;
        z-index: 3;
      }
      .ec-block-box {
        position: relative;
        height: 100%;
        left: -100%;
        transition: transform 0.3s;
        &.show {
          transform: translateX(100%);
        }
        &.wc {
          will-change: transform;
        }
        .ec-block {
          position: absolute;
          bottom: 0;
          width: 800px;
          height: 800px;
          background-color: rgba(255, 255, 255, 0.38);
          transform-origin: right bottom;
        }
        .ec-block-1 {
          right: 0;
          transform: rotate(-16deg);
        }
        .ec-block-2 {
          right: 70px;
          transform: rotate(-5deg);
        }
      }
      .ec-l {
        position: relative;
        height: 100%;
        z-index: 5;
        .ec-l-bg {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
        .ec-l-bg1 {
          width: 516px;
        }
        .ec-l-bg2 {
          width: 586px;
        }
        .ec-l-context {
          width: 450px;
          min-height: $cardHeight;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-left: 32px;
        }
        .common-transition-title-box {
          padding-top: 50px;
          margin-top: -50px;
        }
        .ec-l-title:after {
          top: -50px;
        }
        .ec-l-title-text {
          text-transform: initial;
        }
        .ec-l-text-item {
          margin-top: 24px;
        }
        .ec-l-text {
          width: 420px;
        }
      }
      .ec-r {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        .ec-r-img {
          position: absolute;
        }
        .ec-r-cloud-1 {
          width: 202px;
          right: 0;
          top: 77px;
          transform: translateX(100%);
          animation: 30s move-cloud linear infinite;
        }
        .ec-r-cloud-2 {
          width: 151px;
          top: 115px;
          right: 0;
          transform: translateX(100%);
          animation: 15s move-cloud 3s linear infinite;
        }
        .ec-r-cloud-3 {
          width: 151px;
          bottom: 135px;
          right: 0;
          transform: translateX(100%);
          animation: 24s move-cloud 6s linear infinite;
        }
        .ec-r-platform {
          top: 174px;
          right: 89px;
          width: 562px;
          animation: 8s move-platform infinite alternate;
        }
        .ec-r-fx {
          width: 72px;
          top: 105px;
          right: 309px;
          animation: 3s move-fx 2s infinite alternate;
        }
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@keyframes move-1 {
  0% {
    transform: translate(-10px, -10px);
  }
  25% {
    transform: translate(-100px, 20px);
  }
  50% {
    transform: translate(-130px, 80px);
  }
  75% {
    transform: translate(-10px, 90px);
  }
  100% {
    transform: translate(-10px, -10px);
  }
}
@keyframes move-cloud {
  100% {
    transform: translateX(-1600px);
  }
}
@keyframes move-platform {
  100% {
    transform: translateY(20px);
  }
}
@keyframes move-fx {
  100% {
    transform: translateY(10px);
  }
}
</style>
