<template>
  <div class="cPopForm" :class="{ show: showState }">
    <div class="se-pop-bg" @click="hide"></div>
    <div
      class="se-pop-content sendgrid-subscription-widget widget-2141"
      data-emailerror="Please enter a valid email address"
      data-nameerror="Please enter your name"
      data-checkboxerror="Please tick the box to accept our conditions"
    >
      <div class="se-pop-close" @click="hide"><i class="common-icon-closeB"></i></div>
      <div class="se-pop-logo"><i class="common-icon-chain-core"></i></div>
      <div class="se-pop-title font-bold">Subscribe to Function X News</div>
      <form class="se-form sg-widget" data-token="9ad30ac103445ed5b90745632e2160e3" onsubmit="return false;">
        <div class="se-pop-row">
          <div class="se-pop-item">
            <div class="se-item-input">
              <input class="sg_email" type="email" name="sg_email" required="required" placeholder="you@example.com" />
              <div class="se-pop-item-icon"><i class="common-icon-mailB"></i></div>
              <div class="se-pop-item-border"></div>
            </div>
          </div>
        </div>
        <div class="se-pop-row">
          <div class="se-pop-item btn">
            <!--            <input type="submit" class="sg-submit-btn" id="widget-2141" value="Subscribe" />-->
            <CButton type="comic" :height="42" class="sg-submit-btn" id="widget-2141">Subscribe</CButton>
          </div>
        </div>
        <div class="se-response">
          <div class="sg-response"></div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/common/CButton'
import { EventBus } from '@/tools/bus'
import { sg } from '@/tools/sgWidget'

export default {
  name: 'CPopForm',
  components: {
    CButton
  },
  data() {
    return {
      showState: false
    }
  },
  methods: {
    show() {
      document.body.classList.add('noScroll')
      this.showState = true
    },
    hide() {
      document.body.classList.remove('noScroll')
      this.showState = false
    }
  },
  mounted() {
    sg()
    EventBus.$on('pop-from-show', () => {
      this.show()
    })
  },
  beforeDestroy() {
    document.body.classList.remove('noScroll')
  }
}
</script>

<style>
.noScroll {
  overflow: hidden;
  position: relative;
}
</style>

<style scoped lang="scss">
.cPopForm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: opacity, visibility;
  &.show {
    visibility: visible;
    opacity: 1;
  }
}
.se-pop-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
.se-pop-content {
  position: relative;
  background-color: #fff;
  padding: 58px 24px 64px;
  border: 2px solid #080a32;
  border-radius: 4px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}
.se-pop-close {
  position: absolute;
  width: 34px;
  height: 34px;
  padding: 5px;
  cursor: pointer;
  top: 22px;
  right: 19px;
  transform: translateZ(0);
  i {
    transition: opacity 0.3s;
  }
  &:hover i {
    opacity: 1;
  }
}
.se-pop-logo {
  width: 108px;
  margin-left: auto;
  margin-right: auto;
}
.se-pop-title {
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-top: 18px;
}
.se-form {
  margin-top: 56px;
  width: 100%;
}
.se-pop-row {
  & + .se-pop-row {
    margin-top: 24px;
  }
}
.se-pop-item {
  width: 100%;
  &.checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    input[type='checkbox'] {
      display: none;
      &:checked ~ .se-item-checkbox:before {
        opacity: 1;
      }
    }
  }
  &.btn {
    text-align: center;
    margin-top: 56px;
  }
}

.se-item-input {
  height: 40px;
  border-radius: 20px;
  border: 2px solid transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
  background-color: #fafafa;
  input[type='text'],
  input[type='email'] {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px #fafafa;
    border-radius: 20px;
    padding-right: 15px;
    padding-left: 8px;
    font-size: 14px;
    color: var(--main);
    line-height: 24px;
    font-weight: 400;
    box-sizing: border-box;
    &:focus ~ .se-pop-item-border {
      border-color: var(--main);
    }
    &:focus ~ .se-pop-item-icon {
      opacity: 1;
    }
    &::placeholder {
      color: var(--font-sub);
    }
  }
}
.se-pop-item-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid transparent;
  border-radius: 20px;
  pointer-events: none;
  transition: border-color 0.3s;
}
.se-pop-item-icon {
  width: 24px;
  flex-shrink: 0;
  margin-left: 8px;
  opacity: 0.3;
  transition: opacity 0.3s;
  transform: translateZ(0);
}
.se-item-checkbox {
  width: 14px;
  height: 14px;
  border: 1px solid #080a32;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  margin-left: 14px;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--main);
    opacity: 0;
    transition: opacity 0.3s;
  }
}
.se-item-text {
  display: block;
  font-size: 12px;
  color: var(--main);
  line-height: 14px;
  font-weight: 500;
  a {
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;
  }
}
.se-response {
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 12px;
  min-height: 42px;
  .error {
    color: #fa6237;
  }
  .success {
    color: #0552dc;
  }
}
</style>
