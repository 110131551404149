import { render, staticRenderFns } from "./CButton.vue?vue&type=template&id=6b562c70&scoped=true"
import script from "./CButton.vue?vue&type=script&lang=js"
export * from "./CButton.vue?vue&type=script&lang=js"
import style0 from "./CButton.vue?vue&type=style&index=0&id=6b562c70&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b562c70",
  null
  
)

export default component.exports