<template>
  <div v-show="visible"
       class="loading-wrap">
    <div class="loading-box">
      <div class="loading-icon">
        <i class="common-icon-loading"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  }
}
</script>
<style lang="scss" scoped>
.loading-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  bottom: -1px;
  background: #ffffff;
  z-index: 999999;
  transition: all 0.3s;
}
.loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .loading-icon {
    width: 24px;
    animation: myLoading 1s linear infinite;
  }

  @keyframes myLoading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
