<template>
  <div class="Chain">
    <div class="cn-bg"></div>
    <div class="cn-content">
      <div class="common-transition-title-box single" ref="titleBox">
        <transition name="transition-title">
          <h2 class="common-title-circle lt cn-main-title" ref="title">
            Multi-chain & Cross-chain
          </h2>
        </transition>
      </div>
      <transition name="transition-text-b">
        <div class="common-text cn-main-text" ref="text">
          Function X supports cross-chains internally (between f(x)Core and
          other Function X chains) and among other chains (Ethereum, etc).
          Through decentralized validators we can safely move assets between
          f(x)Core and different chains. This comes with high transaction speed,
          low transaction cost, ability to trade assets, and other useful
          scenarios.
        </div>
      </transition>

      <div class="cn-list" ref="list">
        <div class="cn-list-item" v-for="(item, key) of list" :key="key">
          <div class="cn-list-icon chain-show-text">
            <i :class="item.icon"></i>
          </div>
          <h3 class="cn-list-title chain-show-text">{{ item.title }}</h3>
          <div
            class="common-text cn-list-text chain-show-text"
            v-html="item.text"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { gsap, ScrollTrigger } from '@/tools/gsap'
import { animeTitleShow } from '@/tools/utils'
import { gsapDestroy } from '@/tools/mixin'

export default {
  name: 'Chain',
  data() {
    return {
      list: [
        {
          title: 'f(x)Core',
          icon: 'common-icon-chain-core',
          text:
            'f(x)Core is the core network of the entire Function X ecosystem. It is the interlink of all assets and <span class="common-nowarp">cross-chains</span>.'
        },
        {
          title: 'Cross-chain bridge',
          icon: 'common-icon-chain-cross',
          text:
            'It is the main bridge connecting Function X to Ethereum, Binance Smart Chain and other blockchains.'
        },
        {
          title: 'Multi-chain structure',
          icon: 'common-icon-chain-mutichain',
          text:
            'Function X is deploying multi-chain frameworks to meet different needs and enlarge and expand the Transaction Per Second (TPS) of the whole network.'
        },
        {
          title: 'PoS consensus model',
          icon: 'common-icon-chain-pos',
          text:
            'Delegating $FX token in PoS consensus enhances the security of the network while maintaing high throughput and performance.'
        }
      ]
    }
  },
  mixins: [gsapDestroy],
  mounted() {
    this.commonGsapArray[0] = animeTitleShow({
      titleEl: this.$refs.title,
      textEl: this.$refs.text,
      scrollTrigger: {
        trigger: this.$refs.titleBox,
        start: 'bottom+=50 bottom',
        end: 'bottom+=50 bottom'
      }
    })
    this.commonGsapArray[1] = ScrollTrigger.create({
      trigger: this.$refs.list,
      start: 'top+=150px bottom',
      end: 'top+=150px bottom',
      onEnter: () => {
        gsap.fromTo(
          '.chain-show-text',
          {
            opacity: 0,
            y: 20
          },
          {
            opacity: 1,
            y: 0,
            stagger: 0.1
          }
        )
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variable.scss';

.Chain {
  width: 100%;
  margin-top: 100px;
  position: relative;
  z-index: 1;
  .cn-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('~@/assets/images/home/BG@2x.jpg');
    background-size: cover;
    background-position: center center;
    z-index: -1;
  }
  .cn-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 130px;
    padding-top: 40px;
  }
  .cn-main-title {
    padding-bottom: $transitionTitleBottom;
  }
  .chain-show-text {
    opacity: 0;
  }
  .cn-main-text {
    width: 636px;
    margin-top: 8px;
    text-align: center;
  }
  .cn-list {
    margin-top: 106px;
    width: 956px;
    display: grid;
    grid-column-gap: 176px;
    grid-row-gap: 64px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto auto;
  }
  .cn-list-item {
    text-align: center;
  }
  .cn-list-icon {
    width: 128px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .cn-list-title {
    margin-top: 8px;
  }
  .cn-list-text {
    margin-top: 8px;
  }
}
</style>
