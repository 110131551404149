<template>
  <div class="subscribe">
    <a class="se-content" href="https://medium.com/functionx" target="_blank" rel="noopener noreferrer">
      <div class="se-icon-fx"><i class="common-icon-chain-core"></i></div>
      <div class="se-text font-bold">Subscribe to Function X News</div>
      <div class="se-icon-jump"><i class="common-icon-jumpB"></i></div>
    </a>
  </div>
</template>

<script>
import { EventBus } from '@/tools/bus'

export default {
  name: 'Subscribe',
  data() {
    return {}
  },
  methods: {
    showPop() {
      EventBus.$emit('pop-from-show')
    }
  }
}
</script>

<style scoped lang="scss">
.subscribe {
  .se-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 288px;
    background-color: var(--content-back);
    border-radius: 36px;
    cursor: pointer;
    &:hover {
      color: initial;
    }
  }
  .se-icon-fx {
    width: 108px;
  }
  .se-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin-left: 26px;
  }
  .se-icon-jump {
    width: 24px;
    margin-left: 4px;
  }
}
</style>
