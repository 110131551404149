<template>
  <div class="poptip" :class="{ hover: hover }" @mouseenter="$emit('mouseenter')">
    <slot></slot>
    <div class="poptip-pop" :class="[placement, 'center']">
      <div class="poptip-pop-content" :class="[shadow]">
        <div class="poptip-pop-inner">
          <slot name="pop"></slot>
        </div>
        <div class="poptip-pop-arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPoptip',
  props: {
    placement: {
      type: String, // top bottom
      default: 'top'
    },
    shadow: {
      type: String, // normal 、dark
      default: 'normal'
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  computed: {}
}
</script>

<style scoped lang="scss">
$paddingVertical: 12px;
.poptip {
  position: relative;
  .poptip-pop {
    position: absolute;
    //visibility: hidden;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    pointer-events: none;
    z-index: 1;
    &.center {
      left: 50%;
    }
    &.middle {
    }
    &.top {
      bottom: 100%;
      padding-bottom: $paddingVertical;
      transform: translate(-50%, -10px);
    }
    &.bottom {
      top: 100%;
      padding-top: $paddingVertical + 2px;
      transform: translate(-50%, 10px);
    }
  }

  &:hover,
  &.hover {
    .poptip-pop {
      opacity: 1;
      //visibility: visible;
      pointer-events: initial;
      transform: translate(-50%, 0);
    }
  }

  .poptip-pop-content {
    background-color: #fff;
    position: relative;
    border-radius: 4px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 200%;
      height: 200%;
      transform-origin: left top;
      transform: scale(0.5);
      border: 1px solid rgba(5, 19, 46, 0.08);
      border-radius: 8px;
    }
    &.normal {
      box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.06);
    }
    &.dark {
      box-shadow: 0 8px 20px 0 rgba(8, 10, 50, 0.1);
    }
  }

  .poptip-pop-inner {
  }
  .poptip-pop-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    left: 50%;
    filter: drop-shadow(0 1px 0 rgba(5, 19, 46, 0.08));
    transform-origin: left;
  }
  .poptip-pop {
    &.top .poptip-pop-arrow {
      top: 100%;
      transform: scale(0.5) translate(-50%, -7px);
    }
    &.bottom .poptip-pop-arrow {
      bottom: 100%;
      transform: scale(0.5) rotate(180deg) translate(-50%, -7px);
    }
  }
}
</style>
