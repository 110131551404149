<template>
  <Default>
    <div class="home">
      <ScrollPage />
      <Chain />
      <div class="panel-item">
        <Deploy />
      </div>
      <div class="panel-item">
        <Description />
      </div>
      <div class="panel-item">
        <Fxcoin />
      </div>
      <div class="panel-item" style="margin-top: 0">
        <Exchanges />
      </div>
      <div class="panel-item">
        <Team />
      </div>
      <div class="panel-item" id="fxWallet"></div>
      <div class="panel-item">
        <Wallet />
      </div>
      <div class="panel-item">
        <Subscribe />
      </div>
    </div>
  </Default>
</template>

<script>
import Default from '@/components/layout/Default'
// import Swap from '@/components/home/swap'
// import Governance from '@/components/home/Governance'
// import Synthetic from '@/components/home/Synthetic'
// import Cloud from '@/components/home/Cloud'
import Wallet from '@/components/home/Wallet'
// import Roadmap from '@/components/home/Roadmap'
// import IProgress from '@/components/home/IProgress'
import ScrollPage from '@/components/home/ScrollPage'
import Chain from '@/components/home/Chain'
import Fxcoin from '@/components/home/Fxcoin'
import Team from '@/components/home/Team'
import Subscribe from '@/components/home/Subscribe'
import Deploy from '@/components/home/Deploy.vue'
import Description from '@/components/home/Description.vue'
import Exchanges from '@/components/home/Exchanges.vue'

export default {
  name: 'Home',
  components: {
    Exchanges,
    Description,
    Deploy,
    Subscribe,
    Team,
    Fxcoin,
    Chain,
    ScrollPage,
    // Roadmap,
    Wallet,
    // Cloud,
    // Synthetic,
    Default
    // Swap,
    // Governance,
    // IProgress
  }
}
</script>
<style lang="scss" scoped>
.home {
  //padding-top: 68px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;

  #fxWallet {
    margin-top: 0;
    height: 1px;
  }
}
</style>
